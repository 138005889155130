import React from 'react';

const RewardIcon: React.FC = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17 16V20.523C17 21.2304 16.2855 21.7142 15.6286 21.4514L12.3714 20.1486C12.133 20.0532 11.867 20.0532 11.6286 20.1486L8.37139 21.4514C7.71453 21.7142 7 21.2304 7 20.523V16'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M10.5923 2.51845C11.4035 1.82718 12.5965 1.82718 13.4077 2.51845L14.123 3.12803C14.4706 3.42425 14.9028 3.60327 15.358 3.6396L16.2949 3.71436C17.3572 3.79914 18.2009 4.64275 18.2856 5.70513L18.3604 6.64196C18.3967 7.09722 18.5757 7.52941 18.872 7.87701L19.4815 8.59231C20.1728 9.40347 20.1728 10.5965 19.4815 11.4077L18.872 12.123C18.5757 12.4706 18.3967 12.9028 18.3604 13.358L18.2856 14.2949C18.2009 15.3572 17.3572 16.2009 16.2949 16.2856L15.358 16.3604C14.9028 16.3967 14.4706 16.5758 14.123 16.872L13.4077 17.4816C12.5965 18.1728 11.4035 18.1728 10.5923 17.4815L9.87701 16.872C9.52941 16.5758 9.09722 16.3967 8.64196 16.3604L7.70513 16.2856C6.64275 16.2009 5.79914 15.3572 5.71436 14.2949L5.6396 13.358C5.60327 12.9028 5.42425 12.4706 5.12803 12.123L4.51845 11.4077C3.82718 10.5965 3.82718 9.40347 4.51845 8.59231L5.12803 7.87701C5.42425 7.52941 5.60327 7.09722 5.6396 6.64196L5.71436 5.70513C5.79914 4.64275 6.64275 3.79914 7.70513 3.71436L8.64196 3.6396C9.09722 3.60327 9.52941 3.42425 9.87701 3.12803L10.5923 2.51845Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default RewardIcon;
