import { message as antdMessage } from 'antd';
import { toast, ToastContent } from 'react-toastify';

import { getLoginToken } from '../getToken';

const isTabletOrMobile = () => {
  if (typeof window === 'undefined') return false;
  return window.innerWidth <= 768;
};

if (typeof window !== 'undefined') {
  antdMessage.config({
    duration: 3,
    maxCount: 3,
    prefixCls: 'custom-notification',
  });
}

const showAntdMessage = (
  message: string,
  type: 'info' | 'success' | 'warning' | 'error',
) => {
  if (typeof window === 'undefined') return;

  switch (type) {
    case 'info':
      antdMessage.info(message);
      break;
    case 'success':
      antdMessage.success(message);
      break;
    case 'warning':
      antdMessage.warning(message);
      break;
    case 'error':
      antdMessage.error(message);
      break;
    default:
      antdMessage.info(message);
      break;
  }
};

export const notify = (
  message: ToastContent,
  type: 'info' | 'success' | 'warning' | 'error' = 'info',
  url?: string,
) => {
  const token = getLoginToken();

  if (token === undefined && message !== 'You are not authenticated') {
    return;
  }

  if (!message || message === '') {
    return;
  }
  const options = url
    ? {
        onClick: () => window.open(url, '_blank'),
      }
    : {};

  if (typeof window !== 'undefined') {
    if (isTabletOrMobile() && message) {
      showAntdMessage(message as string, type);
      return;
    }

    toast.dismiss();
    switch (type) {
      case 'info':
        toast.info(message, options);
        break;
      case 'success':
        toast.success(message, options);
        break;
      case 'warning':
        toast.warn(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      default:
        toast.info(message, options);
        break;
    }
  }
};
