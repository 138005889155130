/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';
import { FC } from 'react';

interface Props {
  loaderColor?: 'black' | 'white';
}

const CustomLoader: FC<Props> = ({ loaderColor = 'white' }) => {
  return (
    <div
      className={clsx(
        'fixed top-0 left-0 flex flex-col items-center justify-center z-[9999] overflow-hidden',
        'bg-transparent backdrop-blur-xl',
      )}
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      {loaderColor === 'black' ? (
        <img
          src='/Book_2_Black_Loader.gif'
          className='max-w-[80px] md:max-w-[170px] py-24'
          alt='Loading'
        />
      ) : (
        <img
          src='/Book_2_White_Loader.gif'
          className='max-w-[100px] md:max-w-[120px]'
          alt='Loading'
        />
      )}
      <div className='text-center text-base md:text-lg px-10 md:px-0 text-gray-300'>
        Please wait... We're getting things ready for you!
      </div>
    </div>
  );
};

export default CustomLoader;
