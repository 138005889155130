import { baseForPdf, courseRedirectToken } from '../../apiClients';
import { fetchAndCache } from '../../../components/shared/CacheStroage';
import { fetchAndCacheWithoutPage } from '../../../components/shared/CacheStroage/CacheWithoutPage';
import { Endpoints } from '../../../network';
import { IPostReadingLogs } from '../../../utils/interfaces/Library/ReadingHistory';

export const getAllBookListApi = async ({
  page,
  categoriesId = null,
  keyword,
  limit,
  type,
  categoryId,
  order,
  featured,
  ecomProductId,
  includeCategories,
}: {
  page: string;
  categoriesId?: string | null;
  keyword?: string;
  limit?: string;
  type?: string;
  categoryId?: string;
  order?: string;
  featured?: boolean;
  ecomProductId?: string;
  includeCategories?: string;
}) => {
  const queryParams = new URLSearchParams({
    page,
    ...(categoriesId !== 'undefined' &&
      categoriesId !== null && { category: categoriesId }),
    ...(keyword !== undefined && { title: keyword }),
    ...(limit !== undefined && { limit: limit }),
    ...(type !== undefined && { type }),
    ...(categoryId !== undefined && { rmCategory: categoryId }),
    ...(order !== undefined && { order: order }),
    ...(featured !== undefined && { featured: String(featured) }),
    ...(includeCategories !== undefined && {
      includeCategories: includeCategories,
    }),
    ...(ecomProductId !== undefined && {
      ecomProductId: String(ecomProductId),
    }),
  });
  const urlWithQuery = `${Endpoints.getAllBooks}?${queryParams}`;

  const apiCall = async () => {
    const response = await baseForPdf.get(urlWithQuery);
    return response?.data?.data;
  };

  return fetchAndCache(apiCall, urlWithQuery, page);
};

export const getAllRecentlyViewedBooksApi = async ({
  page,
  keyword,
  limit,
  zl_uid,
  tempUserId,
}: {
  page: string;
  keyword?: string;
  limit?: string;
  zl_uid?: string;
  tempUserId: string;
}) => {
  const queryParams = new URLSearchParams({
    page,
    tempUserId,
    ...(keyword !== undefined && { keyword: keyword }),
    ...(limit !== undefined && { limit: limit }),
    ...(zl_uid !== undefined && { zl_uid }),
  });
  const urlWithQuery = `${Endpoints.recentlyViewed}?${queryParams}`;

  const response = await baseForPdf.get(urlWithQuery);
  return response?.data?.data;
};

export const getAllShortBookListApi = async (title?: string) => {
  const replacedTitle = title ? title.replace(/%2520/g, '%20') : '';
  const urlWithQuery = `${Endpoints.getAllShortBooks}${
    replacedTitle ? `?title=${replacedTitle}` : ''
  }`;
  const apiCall = async () => {
    const response = await baseForPdf.get(urlWithQuery);
    return response?.data?.data;
  };

  return fetchAndCacheWithoutPage(apiCall, urlWithQuery);
};

export const getBookByIdApi = async (id: string) => {
  const response = await baseForPdf.get(`${Endpoints.getAllBooks}/${id}`);
  return response?.data?.data;
};

export const getAllBookCategories = async () => {
  const apiCall = async () => {
    const response = await baseForPdf.get(`${Endpoints.getAllBookCategories}`);
    return response?.data?.data;
  };

  return fetchAndCacheWithoutPage(apiCall, `${Endpoints.getAllBookCategories}`);
};

export const getAllOnBoardingCategories = async (name: string) => {
  const response = await baseForPdf.get(
    `${Endpoints.getAllOnboardingCategories}/${name}`,
  );
  return response?.data?.data;
};

export const getBookCategories = async (id: string) => {
  const apiCall = async () => {
    const response = await baseForPdf.get(`${Endpoints.getAllBooks}/${id}`);
    return response?.data?.data;
  };

  return fetchAndCacheWithoutPage(apiCall, `${Endpoints.getAllBooks}/${id}`);
};

export const getBookBySlug = async (slug: string, userId?: string) => {
  const urlWithQuery = `${Endpoints.getBookDetailsBySlug}/${slug}${
    userId ? `?userId=${userId}` : ''
  }`;
  const response = await baseForPdf.get(urlWithQuery);
  return response?.data?.data;
};

export const getBookBySlugFetch = async (slug: string, userId?: string) => {
  const urlWithQuery = `${Endpoints.getBookDetailsBySlug}/${slug}${
    userId ? `?userId=${userId}` : ''
  }`;

  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_READER_URL}/${urlWithQuery}`,
      { cache: 'force-cache' },
    );

    if (!response.ok) {
      throw new Error(`Error fetching book details: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

export const postReadingLogs = async (data: IPostReadingLogs) => {
  try {
    const response = await baseForPdf.post(`${Endpoints.postReadingLogs}`, {
      ...data,
    });
    return response?.data?.data;
  } catch {
    return null;
  }
};

export const createSecret = async () => {
  const response = await courseRedirectToken.get(`${Endpoints.createSecret}`);
  return response?.data?.data;
};

export const getSampleBookByIdApi = async (id: string) => {
  const response = await baseForPdf.get(`${Endpoints.getSampleBook}/${id}`);
  return response?.data?.data;
};

export const getAllChaptersApi = async (bookId: string) => {
  const response = await baseForPdf.get(
    `${Endpoints.getAllChapters}?bookId=${bookId}`,
  );
  return response?.data?.data;
};

export const getSampleBookById = async (bookId: string) => {
  const response = await baseForPdf.get(
    `${Endpoints.getSampleFileForBooks}/${bookId}`,
  );
  return response;
};

export const getChapterToken = async (chapterId: string) => {
  const response = await baseForPdf.get(
    `${Endpoints.getAllChapters}/${chapterId}`,
  );
  return response?.data?.data;
};

export const getallbookslistApi = async (title?: string) => {
  const replacedTitle = title ? title.replace(/%2520/g, '%20') : '';

  const urlWithQuery = `${Endpoints.getMyBooks}${
    replacedTitle ? `?title=${replacedTitle}` : ''
  }`;
  const response = await baseForPdf.get(urlWithQuery);
  return response?.data;
};

export const getSyncBookListApi = async () => {
  const response = await baseForPdf.get(`${Endpoints.myBookSyncList}`);
  return response?.data;
};

export const getBookToken = async () => {
  const response = await baseForPdf.get(`${Endpoints.getBookToken}`);
  return response?.data;
};
