import mixpanel from 'mixpanel-browser';

import { EventDetails, logEvent } from '../../../hooks/useClickEventTracking';
import {
  BeginCheckoutInterface,
  CampaignSignupData,
  CustomerData,
  IConversion,
  ProductsInterface,
  PurchaseEvent,
  ViewCartEvent,
  ViewItemEvent,
} from '../../../utils/interfaces/gtags/intex';
import { User } from '../../../utils/interfaces/User';

const fbq = typeof window !== 'undefined' ? (window.fbq as any) : undefined;
const googleDataLayer =
  typeof window !== 'undefined' && (window as any).dataLayer;

const environment = process.env.NEXT_PUBLIC_APP_ENV;

const FACEBOOK_EVENTS = {
  VIEW_CONTENT: 'ViewContent',
  ADD_TO_CART: 'AddToCart',
  PURCHASE: 'Purchase',
  INITIATE_CHECKOUT: 'InitiateCheckout',
  ADD_PAYMENT_INFO: 'AddPaymentInfo',
  CAMPAIGN_SIGNUP: 'campaignSignUp',
};

const GOOGLE_EVENTS = {
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  VIEW_CART: 'view_cart',
  PURCHASE: 'purchase',
  BEGIN_CHECKOUT: 'begin_checkout',
  VIEW_ITEM: 'view_item',
  ADD_PAYMENT_INFO: 'add_payment_info',
  CONVERSION: 'conversion',
  CAMPAIGN_SIGNUP: 'campaignSignUp',
};

const MIXPANEL_EVENTS = {
  SIGNUP: 'Signup',
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  VIEW_CART: 'view_cart',
  PURCHASE: 'purchase',
  BEGIN_CHECKOUT: 'begin_checkout',
  VIEW_ITEM: 'view_item',
  ADD_PAYMENT_INFO: 'add_payment_info',
  CONVERSION: 'conversion',
  CAMPAIGN_SIGNUP: 'campaignSignUp',
};

export const trackCampaignSignUp = async (data: CampaignSignupData) => {
  googleDataLayer?.push({
    event: GOOGLE_EVENTS.CAMPAIGN_SIGNUP,
    ecommerce: {
      origin: data?.origin,
      slug: data?.slug,
      email: data?.email,
      name: data?.name,
    },
  });

  if (typeof fbq !== 'undefined') {
    fbq('track', FACEBOOK_EVENTS.CAMPAIGN_SIGNUP, {
      origin: data?.origin,
      slug: data?.slug,
      email: data?.email,
      name: data?.name,
    });
  }

  if (typeof mixpanel !== 'undefined' && environment === 'production') {
    mixpanel.track(MIXPANEL_EVENTS.CAMPAIGN_SIGNUP, {
      origin: data?.origin,
      slug: data?.slug,
      email: data?.email,
      name: data?.name,
    });
  }
};

export const trackAddToCartEvent = async ({
  productData,
  userData,
  pathname,
  productType,
}: {
  productData: ProductsInterface;
  userData?: User | undefined;
  pathname?: string;
  productType?: string;
}) => {
  googleDataLayer?.push({
    event: GOOGLE_EVENTS.ADD_TO_CART,
    ecommerce: {
      currency: 'INR',
      value: productData?.price,
      items: [
        {
          item_id: productData?.id,
          item_name: productData?.heading,
          item_brand: 'Zebralearn',
          price: productData?.price,
          quantity: 1,
        },
      ],
    },
  });

  const eventParams: EventDetails = {
    event_name: 'add_to_cart',
    source: 'global',
    source_type: 'product',
    source_name: productData?.heading,
    source_id: productData?.id?.toString(),
    sub_source: null,
    sub_source_id: null,
    unit: 'INR',
    value: productData?.price,
    meta: {
      product_type: productType,
    },
  };

  logEvent({
    eventDetails: eventParams,
    userData: userData,
    pathname: pathname as string,
  });

  if (typeof mixpanel !== 'undefined' && environment === 'production') {
    mixpanel.track(MIXPANEL_EVENTS.ADD_TO_CART, {
      currency: 'INR',
      value: productData?.price,
      items: [
        {
          item_id: productData?.id,
          item_name: productData?.heading,
          item_brand: 'Zebralearn',
          price: productData?.price,
          quantity: 1,
        },
      ],
    });
  }
};

export const trackRemoveToCartEvent = async (
  productData: ProductsInterface,
) => {
  googleDataLayer?.push({
    event: GOOGLE_EVENTS.REMOVE_FROM_CART,
    ecommerce: {
      currency: 'INR',
      value: productData?.price,
      items: [
        {
          item_id: productData?.id,
          item_name: productData?.heading,
          item_brand: 'Zebralearn',
          price: productData?.price,
          quantity: 1,
        },
      ],
    },
  });
  if (typeof mixpanel !== 'undefined' && environment === 'production') {
    mixpanel.track(MIXPANEL_EVENTS.REMOVE_FROM_CART, {
      currency: 'INR',
      value: productData?.price,
      items: [
        {
          item_id: productData?.id,
          item_name: productData?.heading,
          item_brand: 'Zebralearn',
          price: productData?.price,
          quantity: 1,
        },
      ],
    });
  }
};

export const trackBeginCheckoutCartEvent = ({
  checkoutData,
}: {
  checkoutData: BeginCheckoutInterface;
  userData?: User | undefined;
  pathname?: string;
}) => {
  googleDataLayer?.push({
    event: GOOGLE_EVENTS.BEGIN_CHECKOUT,
    ecommerce: {
      currency: 'INR',
      value: checkoutData?.value || 0,
      coupon: checkoutData?.coupon || null,
      items: checkoutData?.items,
    },
  });

  // if (typeof fbq !== 'undefined') {
  //   fbq('track', FACEBOOK_EVENTS.INITIATE_CHECKOUT, {
  //     content_category: '',
  //     contents: checkoutData?.items,
  //     currency: 'INR',
  //     num_items: checkoutData?.items?.length,
  //     value: checkoutData?.value || 0,
  //   });
  // }
  if (typeof mixpanel !== 'undefined' && environment === 'production') {
    mixpanel.track(MIXPANEL_EVENTS.BEGIN_CHECKOUT, {
      currency: 'INR',
      value: checkoutData?.value || 0,
      coupon: checkoutData?.coupon || null,
      items: checkoutData?.items,
    });
  }
};

export const trackPurchaseCartEvent = ({
  purchaseData,
  customerData,
  userData,
  pathname,
}: {
  purchaseData: PurchaseEvent;
  customerData?: CustomerData;
  userData?: User | undefined;
  pathname?: string;
}) => {
  if (!purchaseData || !purchaseData.items || purchaseData.items.length === 0) {
    return;
  }

  const mergedData = {
    ...purchaseData,
    custom_data: customerData,
  };

  googleDataLayer?.push({
    event: GOOGLE_EVENTS.PURCHASE,
    ecommerce: customerData ? mergedData : { ...purchaseData },
  });

  // For Facebook Pixel
  // if (typeof fbq !== 'undefined') {
  //   const metaPurchaseObject = {
  //     value: parseFloat(String(purchaseData.value).replace(/,/g, '')),
  //     currency: 'INR',
  //     content_name: purchaseData?.productName,
  //     contents: purchaseData.items.map((item) => ({
  //       id: item.item_id,
  //       quantity: item.quantity,
  //     })),
  //     content_type: 'book',
  //     content_ids: purchaseData.items.map((item) => item.item_id),
  //     num_items: purchaseData.items.length,
  //   };
  //   fbq('track', FACEBOOK_EVENTS.PURCHASE, metaPurchaseObject);
  // }

  if (typeof mixpanel !== 'undefined' && environment === 'production') {
    mixpanel.track(MIXPANEL_EVENTS.PURCHASE, {
      ...purchaseData,
    });
  }

  const eventParams: EventDetails = {
    event_name: 'order_successful',
    source: 'ecom',
    source_type: 'orders',
    source_id: purchaseData.transaction_id,
    sub_source: null,
    sub_source_id: null,
    unit: 'INR',
    value: purchaseData?.value,
    meta: {
      payment_mode: purchaseData.paymentType === 'razorpay' ? 'prepaid' : 'cod',
    },
  };

  logEvent({
    eventDetails: eventParams,
    userData: userData,
    pathname: pathname as string,
  });

  purchaseData?.items?.forEach((item) => {
    const eventParamsItems: EventDetails = {
      event_name: 'purchase',
      source: 'global',
      source_type: 'orders',
      source_id: purchaseData.transaction_id,
      sub_source: 'product',
      sub_source_id: item.item_id,
      unit: 'INR',
      value: item?.price,
      sub_source_name: item.item_name,
      meta: {
        payment_mode:
          purchaseData.paymentType === 'razorpay' ? 'prepaid' : 'cod',
        click_source: 'order_successful_screen',
      },
    };

    logEvent({
      eventDetails: eventParamsItems,
      userData: userData,
      pathname: pathname as string,
    });
  });
};

export const trackConversion = (viewData: IConversion) => {
  googleDataLayer?.push({
    event: GOOGLE_EVENTS.CONVERSION,
    ecommerce: { ...viewData },
  });
  if (typeof mixpanel !== 'undefined' && environment === 'production') {
    mixpanel.track(MIXPANEL_EVENTS.CONVERSION, { ...viewData });
  }
};

export const trackViewCartEvent = (viewData: ViewCartEvent) => {
  googleDataLayer?.push({
    event: GOOGLE_EVENTS.VIEW_CART,
    ecommerce: { ...viewData },
  });
};

export const trackViewItemEvent = (viewData: ViewItemEvent) => {
  googleDataLayer?.push({
    event: GOOGLE_EVENTS.VIEW_ITEM,
    ecommerce: { ...viewData },
  });

  // if (typeof fbq !== 'undefined') {
  //   fbq('track', FACEBOOK_EVENTS.VIEW_CONTENT, {
  //     content_ids: viewData?.content_ids,
  //     content_type: viewData?.content_type,
  //     content_name: viewData?.content_name,
  //     value: viewData?.value,
  //     currency: viewData?.currency,
  //   });
  // }
  if (typeof mixpanel !== 'undefined' && environment === 'production') {
    mixpanel.track(MIXPANEL_EVENTS.VIEW_ITEM, { ...viewData });
  }
};

export const trackSignupEvent = (userData: {
  email: string;
  password: string;
  name: string;
  user_id: string;
  phone?: string;
}) => {
  if (typeof mixpanel !== 'undefined' && environment === 'production') {
    mixpanel.alias(userData?.user_id);

    mixpanel.people.set({
      email: userData?.email,
      name: userData?.name,
      user_id: userData?.user_id,
      phone: userData?.phone,
    });
    mixpanel.identify(userData?.user_id);
    mixpanel.track(MIXPANEL_EVENTS.SIGNUP, {
      email: userData?.email,
      name: userData?.name,
      user_id: userData?.user_id,
      phone: userData?.phone,
    });
  }
};

export const trackLoginEvent = (userData: {
  email: string;
  name: string;
  user_id: string;
  phone: string;
}) => {
  if (typeof mixpanel !== 'undefined' && environment === 'production') {
    mixpanel.identify(userData?.user_id);
    mixpanel.people.set({
      email: userData?.email,
      name: userData?.name,
      user_id: userData?.user_id,
      phone: userData?.phone,
    });
  }
};

export const trackLogoutEvent = () => {
  if (typeof mixpanel !== 'undefined' && environment === 'production') {
    mixpanel.reset();
  }
};
