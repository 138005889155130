import { ArrowRightOutlined } from '@ant-design/icons';
import { CheckIcon } from '@heroicons/react/outline';
import { Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import ButtonDefault, { ButtonVariants } from '../shared/basic/button';
import { ShouldRender } from '../shared/basic/ShouldRender';
import CustomLoader from '../shared/Loading';
import { user } from '../../atoms/user';
import { CartItem } from '../../utils/interfaces/cart';
import { FormattedFinalOrder } from '../../utils/interfaces/Orders';
import { User } from '../../utils/interfaces/User';
import { checkoutCardBg, checkoutSM } from '../../../assets';

interface Props {
  data?: CartItem[];
  total?: number;
  loading?: boolean;
  order?: FormattedFinalOrder;
  loadingBook?: boolean;
  books?: {
    _id: string;
    coverImageUrl: string;
    title: string;
    externalLink: string;
    type: string;
    slug: string;
    totalPages: number;
    isExternalLink: boolean;
  }[];
}

const PaymentSuccessful: React.FC<Props> = ({
  loading,
  order,
  books,
  loadingBook,
}) => {
  const userData: User | undefined = useRecoilValue(user);
  const screens = useBreakpoint();
  const router = useRouter();

  const hasDigitalBook = order?.orderDetails.some(
    (detail) => detail.book_type === 'digital',
  );

  const filteredBooks = useMemo(() => {
    if (!books || !order) return [];
    return books
      .filter(
        (book) =>
          !order.orderDetails.some((detail) => detail.name === book.title),
      )
      .slice(0, 3);
  }, [books, order]);

  return (
    <>
      <ShouldRender check={loading}>
        <div className='flex justify-center  py-40'>
          <CustomLoader />
        </div>
      </ShouldRender>
      <ShouldRender check={!loading}>
        <div className='text-center flex items-center justify-center gap-y-4 pb-0 lg:p-16 xl:p-24 relative bg-gradient-to-b from-readerLightGrayBg to-transparent'>
          <div className='mt-20 lg:mt-5 xl:mt-0 relative z-10'>
            <div className='flex justify-center pb-5'>
              <span className='w-12 h-12 md:w-20 md:h-20 rounded-full flex items-center justify-center border-[1px] md:border-[3px] border-[#194C1A] md:border-green-500 p-2'>
                <CheckIcon className='w-8 h-8 md:w-10 md:h-10 text-[#158216] md:text-green-500' />
              </span>
            </div>
            <span className='flex justify-center font-semibold text-xl md:text-4xl text-fadedWhite'>
              Your order is confirmed
            </span>
            <span className='flex justify-center font-base text-sm md:text-lg pt-2 text-fadedWhite'>
              Order ID: <p className='font-semibold pl-2'> {order?.orderId}</p>
            </span>
            <span className='flex justify-center pt-3'>
              <span className='text-xs md:text-base px-5 md:px-0 md:w-4/5 xl:w-3/5 text-[#C7C6CF]'>
                A confirmation mail with tracking link will soon be sent to your
                email address -{' '}
                <b className='font-medium text-fadedWhite'>{userData?.email}</b>
                <ShouldRender check={screens.md}>
                  <div className='flex flex-col md:flex-row gap-3 my-12 px-14 w-full'>
                    <ShouldRender check={hasDigitalBook}>
                      <ButtonDefault
                        size={3}
                        rounded={false}
                        roundedSm={true}
                        onClick={() => router.push('/library/')}
                        variant={ButtonVariants.GRAY}
                        className='w-full text-center '
                      >
                        <span
                          className={clsx(
                            'text-white',
                            'flex text-base gap-x-2 md:px-4  whitespace-nowrap',
                          )}
                        >
                          Start Learning
                        </span>
                      </ButtonDefault>
                    </ShouldRender>
                    <ShouldRender check={!hasDigitalBook}>
                      <ButtonDefault
                        size={3}
                        rounded={false}
                        roundedSm={true}
                        onClick={() => router.push('/')}
                        variant={ButtonVariants.GRAY}
                        className='w-full text-center '
                      >
                        <span
                          className={clsx(
                            'text-white',
                            'flex text-base gap-x-2 md:px-4 whitespace-nowrap',
                          )}
                        >
                          Home
                        </span>
                      </ButtonDefault>
                    </ShouldRender>

                    <ButtonDefault
                      size={2}
                      rounded={false}
                      roundedSm={true}
                      className='w-full border border-[#2C2C2C]'
                      variant={ButtonVariants.WHITE}
                      onClick={() => router.push('/my-orders/')}
                    >
                      <span className='text-bold text-black   w-full  font-bold'>
                        Track Order
                      </span>
                    </ButtonDefault>
                  </div>
                </ShouldRender>
                <ShouldRender check={hasDigitalBook && !screens.md}>
                  <ButtonDefault
                    size={3}
                    rounded={true}
                    onClick={() => router.push('/my-orders/')}
                    className='w-full border border-[#2C2C2C]'
                    variant={ButtonVariants.GRAY}
                  >
                    <span
                      className={clsx(
                        'text-white',
                        'flex text-base gap-x-2 md:px-4 whitespace-nowrap',
                      )}
                    >
                      Track Order
                    </span>
                  </ButtonDefault>
                </ShouldRender>
              </span>
            </span>
            <div className='w-full mt-6 px-5'>
              <div
                className='bg-cartBgColor text-white p-4 md:p-6 xl:p-8 flex  items-center relative rounded-2xl gap-3 md:gap-6'
                style={{
                  boxShadow:
                    '1px 1px 1px 0px #FFFFFF1A inset, 0px -1px 1px 0px #FFFFFF1A inset',
                }}
              >
                <Image
                  src={screens.md ? checkoutCardBg : checkoutSM}
                  alt='checkoutCardBg'
                  className='absolute right-0 z-20 h-full rounded-r-2xl w-auto '
                  style={{
                    maxHeight: '100%',
                    objectFit: 'contain',
                  }}
                />
                <ShouldRender check={screens.md}>
                  <Image
                    src={checkoutCardBg}
                    alt='checkoutCardBg'
                    className='absolute left-0 rotate-180 z-20 h-full rounded-r-2xl w-auto '
                    style={{
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </ShouldRender>

                <div className='relative  min-w-[60px] md:min-w-[80px] -translate-y-12'>
                  <ShouldRender check={!loadingBook && books?.length}>
                    {filteredBooks?.map((book, index) => (
                      <Image
                        key={index}
                        src={book?.coverImageUrl}
                        width={screens.md ? 64 : 82}
                        height={200}
                        alt={book?.title ?? `Book Cover ${index + 1}`}
                        className='w-16 absolute rounded-sm shadow-lg'
                        style={{
                          zIndex: 40 - index * 10,
                          left: `${index * 16}px`,
                          transform: `scale(${1 - index * 0.1})`,
                        }}
                      />
                    ))}
                  </ShouldRender>

                  <ShouldRender check={loadingBook}>
                    <div className='w-full h-full flex items-center justify-center border min-h-[100px] rounded-md border-[#4f4f51]'>
                      <Spin size='small' />
                    </div>
                  </ShouldRender>
                </div>

                <div className='flex flex-col z-20 relative md:flex-row gap-y-3 w-full pl-8 md:pl-0 xl:pl-3 justify-between items-center'>
                  <div className='flex  justify-center flex-col  text-left '>
                    <h2 className='text-base md:text-xl font-semibold mb-1'>
                      Get a Free Book
                    </h2>
                    <p className='text-xs md:text-sm  text-textLightGray  xs:w-4/5 lg:w-full'>
                      Get a free e-book when you invite a friend.
                    </p>
                  </div>
                  <div className='flex justify-start  md:justify-end w-full'>
                    <ButtonDefault
                      size={1}
                      rounded={false}
                      roundedSm={true}
                      onClick={() => router.push('/refer-earn')}
                      variant={ButtonVariants.PRIMARY}
                      className=' whitespace-nowrap w-fit md:p-1 h-fit'
                    >
                      <span className='flex font-semibold text-xs md:text-base px-2   md:px-8 lg:px-2 xl:px-8 gap-x-1 md:gap-x-3 md:py-0.5 '>
                        Invite a friend <ArrowRightOutlined />
                      </span>
                    </ButtonDefault>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShouldRender>
    </>
  );
};

export default PaymentSuccessful;
