import moment from 'moment';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import UAParser from 'ua-parser-js';

import { user } from '../atoms/user';
import { extractSlugAndProductId } from '../components/BookLandingPageComp/SlidBookContainer';
import { logPageView } from '../services/ActivityLogs';
import { User } from '../utils/interfaces/User';
import { pageTitleToEventDetails } from '../utils/logs-routes';

const getDeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    os: `${result.os.name} ${result.os.version}`,
    browser: `${result.browser.name} ${result.browser.version}`,
    device: result.device.type || 'desktop',
    version: result?.browser?.version,
  };
};

export interface EventDetails {
  event_name: string;
  source: string;
  source_type: string;
  source_name?: string;
  source_id?: string;
  sub_source?: string;
  sub_source_id?: string;
  unit?: string;
  value?: number | string;
}

const getEventDetailsForTitle = (
  title: string,
): { details: EventDetails[]; params?: string[] } | undefined => {
  return pageTitleToEventDetails[title];
};

export const getScreenName = (path: string): string => {
  switch (path) {
    case '/':
      return 'home_screen';
    case '/books':
      return 'books_home_screen';
    case '/books/[bookId]':
      return 'books_detail_screen';
    case '/pdf/[book_id]':
      return 'books_reading_screen';
    case '/schools':
      return 'schools_home_screen';
    case '/schools/[schoolId]':
      return 'schools_detail_screen';
    case '/schools/[schoolId]/[postId]':
      return 'schools_reading_screen';
    case '/courses':
      return 'courses_home_screen';
    case '/live':
      return 'live_home_screen';
    case '/live/[slug]':
      return 'live_detail_screen';
    case '/live/watch/[watchId]':
      return 'live_reading_screen';
    case '/library':
      return 'library_screen';
    case '/cart':
      return 'check_out_screen';

    default:
      return path;
  }
};

const usePageTracking = () => {
  const router = useRouter();
  const userData = useRecoilValue<User | undefined>(user);
  const previousPathnameRef = useRef<string | null>(null);
  const pageViewLoggedRef = useRef(false);
  const startTimeRef = useRef<Date | null>(null);
  const previousQueryRef = useRef<Record<string, string | string[]>>({});
  const ignoreSessionLogs = [
    '/library',
    '/library/',
    '/cart/?cartStep=1',
    '/profile',
    '/profile/?activeTab=2',
  ];

  useEffect(() => {
    if (!userData?.zl_uid) return;
    if (ignoreSessionLogs.includes(router.asPath)) return;

    const referrerUrl: string | undefined =
      localStorage.getItem('prevUrl') || undefined;

    const { pathname, query } = router;
    const eventDetailsObject = getEventDetailsForTitle(pathname as string);

    if (!eventDetailsObject) return;

    const { details: eventDetailsArray, params: routeParams } =
      eventDetailsObject;
    const deviceInfo = getDeviceInfo();

    const logEvent = (
      eventDetails: EventDetails,
      value: any,
      additionalData: Record<string, any> = {},
    ) => {
      const meta = {
        platform: 'website',
        os: deviceInfo.os,
        version: deviceInfo.version,
        click_source: getScreenName(referrerUrl as string),
      };

      const eventData: Record<string, any> = {
        source: eventDetails.source,
        source_type: eventDetails.source_type,
        zl_uid: userData.zl_uid,
        event: eventDetails.event_name,
        unit: eventDetails.unit,
        source_name: eventDetails.source_name,
        value,
        meta,
        sub_source_id: eventDetails.sub_source_id,
        sub_source: eventDetails.sub_source,
        ...additionalData,
      };

      if (pathname === '/books/[bookId]') {
        eventData.source_type = query['cs']
          ? 'comingSoon'
          : query['swp']
            ? 'short'
            : 'long';
      }

      if (pathname === '/library') {
        const tabValue = query['tab']?.toString();
        eventData.source =
          tabValue === '2' ? 'schools' : tabValue === '3' ? 'courses' : 'books';
      }

      if (pathname === '/live/watch/[watchId]') {
        eventData.sub_source_id = query['watchId'];
      }

      if (pathname === '/schools/[schoolId]/[postId]') {
        eventData.sub_source_id = query['post_id'];
      }

      if (pathname === '/pdf/[book_id]') {
        eventData.source_type = query['samplePdf'] ? 'short' : 'long';
        eventData.sub_source = query['samplePdf'] ? 'short' : 'long';
      }

      const productId = extractSlugAndProductId(router.asPath);

      routeParams?.forEach((param) => {
        if (param === 'productId') {
          eventData.source_id = productId;
        } else {
          eventData.source_id = query[param];
        }
      });

      logPageView(eventData);
    };

    const logPageViewEvent = () => {
      if (!pageViewLoggedRef.current) {
        eventDetailsArray.forEach((eventDetails) => {
          if (eventDetails.event_name.includes('view')) {
            logEvent(eventDetails, eventDetails.value);
          }
        });
        pageViewLoggedRef.current = true;
      }
    };

    const logSessionEvent = () => {
      if (startTimeRef.current) {
        const endTime = new Date();
        const durationInSeconds = Math.round(
          (endTime.getTime() - startTimeRef.current.getTime()) / 1000,
        );

        if (durationInSeconds > 0) {
          eventDetailsArray.forEach((eventDetails) => {
            if (eventDetails.event_name.includes('session')) {
              logEvent(eventDetails, durationInSeconds, {
                activity_start: moment(startTimeRef.current).format(
                  'YYYY-MM-DD HH:mm:ss',
                ),
                activity_end: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
              });
            }
          });
        }
      }
    };

    const currentUrl = router.pathname;
    localStorage.setItem('prevUrl', localStorage.getItem('currentUrl') || '');
    localStorage.setItem('currentUrl', currentUrl);

    logPageViewEvent();
    startTimeRef.current = new Date();

    const handleRouteChange = () => {
      const newQuery = router.query;

      const filteredQuery: Record<string, string | string[]> = Object.keys(
        newQuery,
      )
        .filter((key) => newQuery[key] !== undefined)
        .reduce(
          (acc, key) => {
            acc[key] = newQuery[key] as string | string[];
            return acc;
          },
          {} as Record<string, string | string[]>,
        );

      const watchedQueryParams = ['search', 'filter', 'id'];

      const hasPathnameChanged =
        previousPathnameRef.current !== router.pathname;

      const hasNonWatchedParamsChanged = Object.keys(filteredQuery).some(
        (param) =>
          !watchedQueryParams.includes(param) &&
          previousQueryRef.current[param] !== filteredQuery[param],
      );

      if (hasPathnameChanged || hasNonWatchedParamsChanged) {
        logSessionEvent();
        pageViewLoggedRef.current = false;
        startTimeRef.current = new Date();
      }

      previousPathnameRef.current = router.pathname;
      previousQueryRef.current = filteredQuery;
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    window.addEventListener('beforeunload', logSessionEvent);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      window.removeEventListener('beforeunload', logSessionEvent);
      logSessionEvent();
    };
  }, [router.pathname, userData]);

  return null;
};

export default usePageTracking;
