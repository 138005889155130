import { ArrowRightIcon } from '@heroicons/react/outline';
import { Affix, Button } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { tokenUpdate } from '../../../atoms/authModalControl';
import { localUserID, user } from '../../../atoms/user';
import AuthorDetailsSection from '../../../components/BookLandingPageComp/AuthorDeatils';
import BookDescription from '../../../components/BookLandingPageComp/BookDescription';
import CurriculumSection from '../../../components/BookLandingPageComp/CurriculumSection';
import HeroSection from '../../../components/BookLandingPageComp/HeroSection';
import IframeLandingPage from '../../../components/BookLandingPageComp/IframeLandingPage';
import RecommendedAndOverViewSection from '../../../components/BookLandingPageComp/RecommendedAndOverViewSection';
import ReviewsLandingPage from '../../../components/BookLandingPageComp/ReviewsLandingPage';
import SlidBookContainer from '../../../components/BookLandingPageComp/SlidBookContainer';
import SneakPeekSection from '../../../components/BookLandingPageComp/SneakPeekSection';
import Products from '../../../components/ProjectPage/Products';
import ButtonDefault, {
  ButtonVariants,
} from '../../../components/shared/basic/button';
import { pageTitle } from '../../../components/shared/basic/customHead';
import Section from '../../../components/shared/basic/section';
import { ShouldRender } from '../../../components/shared/basic/ShouldRender';
import { formateErrorObject } from '../../../components/shared/formateErrorObject';
import { getLoginToken } from '../../../components/shared/getToken';
import { trackAddToCartEvent } from '../../../components/shared/Gtags';
import {
  extractAuthorDetailsData,
  extractCurriculumLandingPageData,
  extractHeroSectionData,
  extractIBookDescriptionData,
  extractIframeData,
  extractRecommendedAndOverViewSectionData,
  extractSlidBookContainerData,
  extractSneakPeekData,
} from '../../../components/shared/LandingPageHelper';
import CustomLoader from '../../../components/shared/Loading';
import { notify } from '../../../components/shared/notify';
import { Endpoints } from '../../../network';
import { addToCart, addToRecentlyViewed } from '../../../services/Cart';
import { getBookLandingPageData } from '../../../services/LandingPage';
import {
  getBookBySlug,
  getBookBySlugFetch,
} from '../../../services/Library/Books';
import { getAuthorProfileById } from '../../../services/School';
import { AddToCartProps } from '../../../utils/interfaces/cart';
import {
  IAuthorDetails,
  IBookDescription,
  IBookLandingPage,
  ICurriculumLandingPageData,
  IHeroSectionLandingPage,
  IIframeLandingPage,
  IRecommendedAndOverViewSection,
  ISlidBook,
  ISneakPeek,
} from '../../../utils/interfaces/landingPage';
import { IBookDetails } from '../../../utils/interfaces/Products/books';
import { User } from '../../../utils/interfaces/User';

interface Props {
  metaData: {
    title: string;
    description: string;
    author: string;
    keywords: string;
    image: string;
  } | null;
}

export const generateUrlForImage = (
  baseUrl: string,
  endpoint: string,
  id: string,
): string => {
  const encodedId = encodeURIComponent(id);
  const url = `${baseUrl}${endpoint}${encodedId}`;
  return url;
};

const BookDetailsPage: React.FC<Props> = ({ metaData }) => {
  const [bookLandingDetails, setBookLandingDetails] =
    useState<IBookLandingPage>();
  const [loading, setLoading] = useState(false);
  const [loadingAddToCart, setLoadingAddToCart] = useState({
    value: false,
    id: 0,
  });
  const [token, setToken] = useState('');
  const [offset, setOffset] = useState<number>(0);
  const [isTokenChanged, setIsTokenChanged] = useRecoilState(tokenUpdate);
  const [correctedSlug, setCorrectedSlug] = useState<string>();

  const [bookDetails, setBooksDetails] = useState<IBookDetails>();

  const [isCalled, setIsCalled] = useState(false);
  const router = useRouter();
  const screens = useBreakpoint();
  const localUserId = useRecoilValue(localUserID);
  const userData: User | undefined = useRecoilValue(user);
  const [authorsData, setAuthorsData] = useState<{
    follower_count: string;
    user_name: string;
  }>();

  const { bookId, swp } = router.query;

  const getBookDetailsById = async (bookId: string) => {
    try {
      setLoading(true);
      const response = await getBookLandingPageData(bookId);

      if (response?.data?.correctedSlug) {
        setCorrectedSlug(response?.data?.correctedSlug);
      }
      setBookLandingDetails(response?.data?.form);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  const getBookDetailsByIdSwp = async (bookId: string, userId?: string) => {
    try {
      setLoading(true);

      const response = await getBookBySlug(bookId, userId && userId);

      const formateData = {
        authorName: response?.authors?.[0],
        bookName: response?.title,
        desc: response?.description,
        lang: 'English',
        type: 'Book',
        _id: response?._id,
        authorAvatar: generateUrlForImage(
          `${process.env.NEXT_PUBLIC_API_READER_URL}`,
          `${Endpoints.getAuthorAvatar}/`,
          `${response?.author_list?.[0]?._id}`,
        ),
        totalPages: response?.totalPages,
        bookLSample: response?._id,
        bookType: response?.type,
        isSubscribed: response?.subscribe?.subscribed || false,
        image: generateUrlForImage(
          `${process.env.NEXT_PUBLIC_API_READER_URL}/`,
          `${Endpoints.getCoverImagesForBooks}/`,
          `${response?._id}`,
        ),
      };
      setBooksDetails(formateData);
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateOffset = () => {
      const containerHeight =
        document.querySelector('.slid-book-container')?.clientHeight || 0;
      const screenHeight = window.innerHeight;
      setOffset((screenHeight - containerHeight) / 15);
    };

    updateOffset();

    window.addEventListener('resize', updateOffset);

    return () => {
      window.removeEventListener('resize', updateOffset);
    };
  }, []);

  useEffect(() => {
    if (bookId && userData) {
      if (swp === 'false') {
        getBookDetailsByIdSwp(bookId as string, userData && userData?.zl_uid);
      }
    }
  }, [userData, bookId]);

  useEffect(() => {
    if (bookId && !isCalled) {
      if (swp === 'false') {
        getBookDetailsByIdSwp(bookId as string);
      } else {
        getBookDetailsById(bookId as string);
      }

      setIsCalled(true);
    }
  }, [bookId, isCalled, userData, token]);

  const fetchData = async () => {
    try {
      const token = await getLoginToken();
      setToken(token as string);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  useEffect(() => {
    fetchData();
    if (isTokenChanged) {
      setIsTokenChanged(false);
    }
  }, [isTokenChanged]);

  useEffect(() => {
    const formattedSlug = `/books/${bookLandingDetails?.productSlug}-${bookLandingDetails?.readersProductId}`;
    if (
      correctedSlug &&
      bookLandingDetails?.readersProductId &&
      (router.pathname?.split('?')[0] || '') !== formattedSlug
    ) {
      router.replace(formattedSlug, undefined, { shallow: true });
    }
  }, [correctedSlug, bookLandingDetails]);

  const handleAddDataToCart = async (
    payload: AddToCartProps,
    type: 'hardCopy' | 'digitalCopy',
  ) => {
    try {
      setLoadingAddToCart({ value: true, id: payload.id });

      await addToCart(payload);
      addToRecentlyViewed(payload.id, userData?.zl_uid);
      notify(
        `${slidBookContainerData?.bookName} ${type === 'hardCopy' ? 'Hard Copy' : 'Digital Copy'}  added to cart successfully!`,
        'success',
      );
      router.push('/cart');
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    } finally {
      setLoadingAddToCart({ value: false, id: payload.id });
    }
  };

  const heroSectionData: IHeroSectionLandingPage | undefined =
    bookLandingDetails ? extractHeroSectionData(bookLandingDetails) : undefined;

  const slidBookContainerData: ISlidBook | undefined = bookLandingDetails
    ? extractSlidBookContainerData(bookLandingDetails)
    : undefined;

  const bookDescriptionData: IBookDescription | undefined = bookLandingDetails
    ? extractIBookDescriptionData(bookLandingDetails)
    : undefined;

  const recommendedAndOverViewSectionData:
    | IRecommendedAndOverViewSection
    | undefined = bookLandingDetails
    ? extractRecommendedAndOverViewSectionData(bookLandingDetails)
    : undefined;

  const curriculumLandingPageData: ICurriculumLandingPageData | undefined =
    bookLandingDetails
      ? extractCurriculumLandingPageData(bookLandingDetails)
      : undefined;

  const sneakPeekData: ISneakPeek | undefined = bookLandingDetails
    ? extractSneakPeekData(bookLandingDetails)
    : undefined;

  const authorDetailsData: IAuthorDetails | undefined = bookLandingDetails
    ? extractAuthorDetailsData(bookLandingDetails)
    : undefined;

  const iframeData: IIframeLandingPage | undefined = bookLandingDetails
    ? extractIframeData(bookLandingDetails)
    : undefined;

  const handleGetAuthorProfileById = async (zl_uid: string) => {
    try {
      const data = await getAuthorProfileById({ zl_uid });
      setAuthorsData({
        user_name: data?.data?.profile_data?.user_name,
        follower_count: data?.data?.follower_count,
      });
    } catch (error: any) {
      notify(formateErrorObject(error), 'error');
    }
  };

  useEffect(() => {
    if (heroSectionData?.authorZlUid) {
      handleGetAuthorProfileById(heroSectionData?.authorZlUid);
    }
  }, [heroSectionData?.authorZlUid]);

  return (
    <>
      <Head>
        <title>{pageTitle(metaData?.title as string)}</title>

        <meta
          name='description'
          content={metaData?.description as string}
          key='description'
        />
        <meta name='keywords' content={metaData?.keywords as string} />
        <meta name='author' content={metaData?.author as string} />
        <meta itemProp='name' content='ZebraLearn' />
        <meta
          itemProp='description'
          content={metaData?.description as string}
        />
        <meta itemProp='image' content={metaData?.image as string} />
        <meta name='twitter:card' content={metaData?.description as string} />
        <meta name='twitter:site' content='ZebraLearn.com' />
        <meta name='twitter:title' content={metaData?.title as string} />
        <meta
          name='twitter:description'
          content={metaData?.description as string}
        />
        <meta name='twitter:creator' content={metaData?.author as string} />
        <meta name='twitter:image:src' content={metaData?.image as string} />
        <meta property='og:title' content={metaData?.title as string} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://zebralearn.com/' />
        <meta property='og:image' content={metaData?.image as string} />
        <meta
          property='og:description'
          content={metaData?.description as string}
        />
        <meta property='og:site_name' content={metaData?.author as string} />
        <meta property='article:section' content='Website Section' />
        <meta property='article:tag' content={metaData?.title as string} />
      </Head>
      <ShouldRender
        check={!loading && bookLandingDetails && swp?.toString() !== 'false'}
      >
        <div className=' relative flex h-full mt-14  '>
          <div className='w-full pb-20 md:pb-24 lg:pb-5'>
            <Section direction='left'>
              <div>
                <ShouldRender check={!screens.lg}>
                  <Section>
                    <div className='h-full z-40'>
                      <SlidBookContainer
                        slidBookContainerData={
                          slidBookContainerData as ISlidBook
                        }
                      />
                    </div>
                  </Section>
                </ShouldRender>
                <div className=' bg-bgColor lg:bg-transparent md:py-4 lg:py-[56px] px-4  md:px-10 lg:px-20 relative overflow-hidden'>
                  <div className='absolute customGradient inset-0 hidden md:block   translate-y-2 backdrop-blur-xl -z-10'></div>
                  <div className='absolute inset-0 hidden lg:block  bg-[#000000CC]  -z-20'></div>

                  <div className='absolute inset-0 hidden lg:block  w-full h-full -z-30'>
                    <Image
                      src={heroSectionData?.bookImage as string}
                      alt='Background Image'
                      layout='fill'
                      priority
                      className='rotate-90    transform w-full h-auto absolute top-0 left-0'
                    />
                  </div>

                  <ShouldRender check={!loading && heroSectionData}>
                    <div className='z-40 '>
                      <HeroSection
                        authorsData={authorsData}
                        heroSectionData={
                          heroSectionData as IHeroSectionLandingPage
                        }
                      />
                    </div>
                  </ShouldRender>
                </div>

                <div className='lg:w-1/2 xl:w-2/3 pt-[56px] pb-[20px] md:py-[56px] px-5 md:px-10 lg:pl-10 lg:pr-0 xl:px-20 text-white flex flex-col gap-y-[56px]'>
                  <BookDescription
                    description={
                      bookDescriptionData?.bookDescription as string[]
                    }
                    whatYouWillLearn={
                      bookDescriptionData?.whatYouWillLearn as string[]
                    }
                  />
                  <IframeLandingPage data={iframeData as IIframeLandingPage} />
                  <RecommendedAndOverViewSection
                    recommendedAndOverViewSectionData={
                      recommendedAndOverViewSectionData as IRecommendedAndOverViewSection
                    }
                  />

                  <CurriculumSection
                    data={
                      curriculumLandingPageData as ICurriculumLandingPageData
                    }
                  />

                  <SneakPeekSection
                    data={sneakPeekData?.sneakPeek as string[]}
                  />
                  <AuthorDetailsSection
                    data={authorDetailsData as IAuthorDetails}
                    authorsData={authorsData}
                  />
                  <ReviewsLandingPage
                    isLoggedIn={!!token}
                    digitalCoverId={
                      bookLandingDetails?.digitalProductId?.toString() as string
                    }
                    hardCoverId={
                      bookLandingDetails?.hardCoverProductId?.toString() as string
                    }
                  />
                </div>
              </div>
            </Section>
          </div>
          <ShouldRender check={screens?.lg}>
            <div className='absolute right-20 -top-[30px]'>
              <Affix offsetTop={offset}>
                <Section direction='right'>
                  <div className='slid-book-container transition-all duration-300 ease-in-out'>
                    <SlidBookContainer
                      slidBookContainerData={slidBookContainerData as ISlidBook}
                    />
                  </div>
                </Section>
              </Affix>
            </div>
          </ShouldRender>
          <ShouldRender check={!screens.lg}>
            <div
              className={clsx(
                !screens.lg && 'fixed -bottom-2 left-0',
                'w-full',
              )}
            >
              <div className='bg-[#131313CC] bg-opacity-75 px-2 xxs:px-4 md:px-10 lg:px-0 py-4 backdrop-blur-sm flex gap-x-2 xxs:gap-x-4 pb-6'>
                <ButtonDefault
                  size={1}
                  isWidthFull={true}
                  rounded={false}
                  roundedSm={true}
                  variant={ButtonVariants.WHITE}
                  enableScaling={false}
                  loading={
                    Number(bookLandingDetails?.digitalProductId) ===
                      loadingAddToCart.id && loadingAddToCart.value
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddDataToCart(
                      {
                        id: Number(
                          bookLandingDetails?.digitalProductId,
                        ) as number,
                        temp_user_id: localUserId,
                        quantity: 1,
                      },
                      'digitalCopy',
                    );

                    trackAddToCartEvent({
                      productData: {
                        price: Number(
                          bookLandingDetails?.softCopyPrice?.discountedPrice,
                        ),
                        id: Number(bookLandingDetails?.digitalProductId),
                        heading: bookLandingDetails?.productTitle as string,
                        quantity: 1 as number,
                      },
                      userData,
                      pathname: router.pathname,
                      productType: 'digitalcopy',
                    });
                  }}
                  className='w-full text-center text-sm group '
                >
                  <div className='flex justify-between items-center w-full '>
                    <span className='flex flex-col justify-start'>
                      <p className='w-full text-start font-semibold text-[#737373] text-[10px]'>
                        Digital copy
                      </p>
                      <span className='flex gap-x-2 items-baseline pl-1'>
                        <p className='text-sm font-semibold whitespace-nowrap'>
                          ₹ {bookLandingDetails?.softCopyPrice?.discountedPrice}
                        </p>
                        <p className='line-through text-[#AEAEAE] text-[13px] font-semibold whitespace-nowrap'>
                          ₹ {bookLandingDetails?.softCopyPrice?.mrpPrice}
                        </p>
                      </span>
                    </span>
                    <span className=''>
                      <ArrowRightIcon className='w-5 text-[#AEAEAE] transform transition-transform duration-300 ease-in-out group-hover:-rotate-[20deg]' />
                    </span>
                  </div>
                </ButtonDefault>
                <ShouldRender
                  check={bookLandingDetails?.hardCoverAvailable !== false}
                >
                  <ButtonDefault
                    size={4}
                    rounded={false}
                    isWidthFull={true}
                    roundedSm={true}
                    loading={
                      Number(bookLandingDetails?.hardCoverProductId) ===
                        loadingAddToCart.id && loadingAddToCart.value
                    }
                    variant={ButtonVariants.PRIMARY}
                    enableScaling={false}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddDataToCart(
                        {
                          id: Number(
                            bookLandingDetails?.hardCoverProductId,
                          ) as number,
                          temp_user_id: localUserId,
                          quantity: 1,
                        },
                        'hardCopy',
                      );

                      trackAddToCartEvent({
                        productData: {
                          price: Number(
                            bookLandingDetails?.hardCopyPrice?.discountedPrice,
                          ),
                          id: Number(bookLandingDetails?.hardCoverProductId),
                          heading: bookLandingDetails?.productTitle as string,
                          quantity: 1 as number,
                        },
                        userData,
                        pathname: router.pathname,
                        productType: 'hardcopy',
                      });
                    }}
                    className='w-full text-center text-sm   group'
                  >
                    <div className='flex justify-between items-center w-full'>
                      <span className='flex flex-col justify-start'>
                        <p className='w-full text-start font-semibold  text-[10px]'>
                          Hard copy
                        </p>
                        <span className='flex gap-x-2 items-baseline pl-1'>
                          <p className='text-sm font-semibold whitespace-nowrap'>
                            ₹{' '}
                            {bookLandingDetails?.hardCopyPrice?.discountedPrice}
                          </p>
                          <p className='line-through text-[#8A2800] text-[13px]  font-semibold whitespace-nowrap'>
                            ₹ {bookLandingDetails?.hardCopyPrice?.mrpPrice}
                          </p>
                        </span>
                      </span>
                      <span>
                        <ArrowRightIcon className='w-5 transform transition-transform duration-300 ease-in-out group-hover:-rotate-[20deg] text-[#8A2800]' />
                      </span>
                    </div>
                  </ButtonDefault>
                </ShouldRender>

                <ShouldRender
                  check={bookLandingDetails?.hardCoverAvailable === false}
                >
                  <Button
                    size='small'
                    type='text'
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      background: '#141414',

                      border: 'none',
                      borderRadius: '8px',
                      padding: '5px 15px',
                      zIndex: 1,
                      boxShadow: '0px 4px 4px 0px #0000004D',
                    }}
                    className='w-full h-full min-h-[62px] hover:text-white text-xs font-semibold'
                  >
                    <span
                      style={{
                        position: 'absolute',
                        inset: 0,
                        zIndex: -1,
                        borderRadius: 'inherit',
                        padding: '1.3px',
                        background:
                          'linear-gradient(180deg, #6199FF 0%, #045CFB 100%)',
                        WebkitMask:
                          'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',

                        WebkitMaskComposite: 'xor',
                        maskComposite: 'exclude',
                      }}
                    />
                    <div className='flex justify-center items-center w-full '>
                      <span className='flex flex-col justify-start gap-y-1.5'>
                        <p className='w-full text-center font-semibold text-white text-[10px]'>
                          Hard Copy
                        </p>
                        <span
                          className='flex gap-x-2 font-bold text-xs items-baseline pl-1'
                          style={{
                            background:
                              'linear-gradient(to bottom, #6199FF, #045CFB)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                          }}
                        >
                          Available SOON
                        </span>
                      </span>
                    </div>
                  </Button>
                </ShouldRender>
              </div>
            </div>
          </ShouldRender>
        </div>
      </ShouldRender>

      <ShouldRender check={swp?.toString() === 'false'}>
        <Section>
          <div id='products' className={` flex items-center w-full `}>
            <ShouldRender check={!loading}>
              <Products productData={bookDetails} token={token} />
            </ShouldRender>
          </div>
        </Section>
      </ShouldRender>

      <ShouldRender check={loading}>
        <CustomLoader />
      </ShouldRender>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = async ({
  query,
}) => {
  try {
    const { bookId, swp } = query;

    const parts = (bookId as string).split('-');
    const extractedPart = parts.splice(0, parts.length - 1)?.join('-');
    const metaData = await getBookBySlugFetch(
      (swp === 'false' ? bookId : extractedPart) as string,
    );

    if (!metaData) {
      throw new Error('Invalid metaData response');
    }

    const formattedMessage = {
      title: metaData?.title || '',
      description: metaData?.description || '',
      image:
        generateUrlForImage(
          `${process.env.NEXT_PUBLIC_API_READER_URL}/`,
          `${Endpoints.getCoverImagesForBooks}/`,
          `${metaData?._id}`,
        ) || '',
      author: metaData?.author?.[0] || '',
      keywords: metaData?.data?.keyword || '',
    };

    return {
      props: {
        metaData: formattedMessage,
      },
    };
  } catch (error) {
    notify('Error fetching metaData:', 'error');

    return {
      props: {
        metaData: null,
      },
    };
  }
};

export default BookDetailsPage;
