export const fetchAndCache = async <T>(
  apiCall: () => Promise<T>,
  apiUrl: string,
  page?: number | string,
): Promise<T> => {
  const cacheKey = page ? `${apiUrl}_page_${page}` : apiUrl;
  const cachedData = localStorage.getItem(cacheKey);
  const expiryDays = process.env.NEXT_PUBLIC_CASH_STORAGE_EXPIRY || 3;

  if (cachedData) {
    const parsedData = JSON.parse(cachedData);
    const { timestamp, data } = parsedData;
    const now = new Date().getTime();
    if (now - timestamp < Number(expiryDays) * 60 * 60 * 1000) {
      return data;
    }
  }

  const response = await apiCall();

  if (page) {
    const timestamp = new Date().getTime();
    localStorage.setItem(
      cacheKey,
      JSON.stringify({ timestamp, data: response }),
    );
  }

  return response;
};
