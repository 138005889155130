import {
  App as AntdApp,
  ConfigProvider,
  theme as antdTheme,
  ThemeConfig,
} from 'antd';
import {
  AnimatePresence,
  domAnimation,
  LazyMotion,
  motion,
} from 'framer-motion';
import mixpanel from 'mixpanel-browser';
import { AppProps } from 'next/app';
import { Montserrat } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';

import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.scss';
import '../styles/square.scss';
import '../styles/toolTip.scss';

import Custom503 from './503';
import Layout from '../components/Layout';

const montserrat = Montserrat({
  subsets: ['latin'],
  fallback: ['Arial', 'sans-serif'],
});
const config: ThemeConfig = {
  token: { colorPrimary: '#FF5612', fontFamily: 'inherit' },
};

export default function App({ Component, pageProps }: AppProps) {
  const { pathname, route } = useRouter();
  const environment = process.env.NEXT_PUBLIC_APP_ENV || '';
  const maintenanceMode = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true';
  const router = useRouter();

  const pagesWithoutLayout = [
    '/pdfReader/[book_id]',
    '/pdfReader/[book_id]/[without_email]',
  ];
  const pagesWithoutRouteAnimation = ['/books/[bookId]'];
  const excludeLayout = pagesWithoutLayout.includes(pathname);
  const excludeRouteAnimation = pagesWithoutRouteAnimation.includes(pathname);

  const trackPageView = (url: string, pageTitle: string) => {
    mixpanel.track(pageTitle, {
      url: url,
      pageTitle: pageTitle,
    });
  };

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_MIXPANEL_TOKEN &&
      environment === 'production'
    ) {
      // MixPanel Initialize
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string);

      let previousPathname = pathname;

      const handleRouteChange = (url: string) => {
        if (typeof window !== 'undefined' && previousPathname === pathname) {
          const pageTitle = document.title;
          trackPageView(url, pageTitle);
          previousPathname = pathname;
        }
      };
      handleRouteChange(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    const handleRouteChange = () => {
      console.clear();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const TAG_ID = process.env.NEXT_PUBLIC_GOOGLE_ID_TAG;

      (window as any).dataLayer = (window as any).dataLayer || [];

      const gtag = function (...args: any[]): void {
        (window as any).dataLayer.push(...args);
      };

      gtag('js', new Date());
      gtag('config', TAG_ID, { debug_mode: true });

      (window as any).gtag = gtag;
    }
  }, []);

  if (maintenanceMode)
    return (
      <div className={montserrat.className}>
        <ConfigProvider
          theme={{ ...config, algorithm: [antdTheme.darkAlgorithm] }}
        >
          <Custom503 />{' '}
        </ConfigProvider>
      </div>
    );

  const content = (
    <motion.span
      key={route}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className='no-scrollbar w-full h-full relative overflow-x-hidden'
    >
      <Component {...pageProps} />
    </motion.span>
  );

  const robotsContent =
    process.env.NEXT_PUBLIC_APP_ENV !== 'production'
      ? 'noindex, nofollow'
      : 'index, follow';
  const canonicalUrl = `https://zebralearn.com${router.asPath.split('?')[0]}`;
  return (
    <>
      <Head>
        <meta
          name='keywords'
          content='Bestselling Books, Courses, Learning, School, ecommerce, Affiliate, marketing, Online learning, E-books, Education, Learning platform, Study materials, Digital library, Online courses, Educational resources, Self-paced learning, Interactive learning, Distance learning, E-learning, Skill development, Knowledge acquisition, Study guides, Learning materials, Academic resources, Online education, Study resources, Digital textbooks, Tutorial videos, Exam preparation, Test materials, Study aids, Reference materials, Audio books, Video lectures, Educational content, Learning modules, Study notes, Webinars, Interactive courses, Study platform, Certification courses, Online training, Skill enhancement, Academic courses, Professional development, Career advancement, Learning management system, Virtual classroom, Online workshops, Exam practice, Study skills, Academic support, Educational technology, Lifelong learning, Study habits, Course materials, Online tutorials, Learning community, Study groups, Practice tests, Online degrees, Course catalogs, Study strategies, Learning resources, Educational tools, Online seminars, Online study groups, Homework help, Study techniques, Digital learning, Educational courses, Online classrooms, Learning environment, Study assistance, Exam tips, Study motivation, Skill-based courses, Career skills, Online textbooks, Study plans, Online coaching, Study sessions, Study aids, Online quizzes, Academic success, Online support, Study schedule, Learning objectives, Exam readiness, Academic achievement, Learning outcomes, Course completion, Test preparation '
        />
        <meta name='robots' content={robotsContent} key='robotsId' />

        <meta name='googlebot' content={robotsContent} />
        <meta charSet='utf-8' />
        <meta property='og:site_name' content='ZebraLearn' />
        <link
          rel='canonical'
          href={canonicalUrl || 'https://zebralearn.com'}
          key='canonical'
        />
        <meta property='og:locale' content='en_IN' />
        <meta property='og:title' content='Zebralearn' key='ogtitle' />
        <meta
          name='description'
          content='Discover best books publisher in India specializing in non-fiction books. ZebraLearn offers cutting-edge design & technology insights, simplifying complex topics.'
          key='description'
        />
        <meta
          property='og:description'
          content='ZebraLearn: Where finance enthusiasts find books and courses tailored to their needs.'
          key='ogdesc'
        />
        <meta property='og:type' content='website' key='website' />
        <meta property='og:url' content='https://zebralearn.com' key='ogurl' />
        <meta
          property='og:image'
          content='https://zebralearn-store.s3.ap-south-1.amazonaws.com/OG+1.png?tr=f-png'
          key='ogimage'
        />
        <meta property='og:image:alt' content='Zebralearn' />
        <meta property='og:image:type' content='image/png' />
        <meta property='og:image:width' content='1200' key='ogimagewidth' />
        <meta property='og:image:height' content='630' key='ogimageheight' />
        <meta
          property='zebralearn:published_time'
          content='2024-01-11T11:35:00+07:00'
        />
        <meta
          property='zebralearn:modified_time'
          content='2024-01-11T11:35:00+07:00'
        />
        <meta
          property='zebralearn:author'
          content='https://www.linkedin.com/company/zebra-learn/mycompany/'
        />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@zebralearn' />
        <meta name='twitter:creator' content='@zebralearn' />
        <meta
          name='twitter:title'
          content='ZebraLearn: ZebraLearn: Where finance enthusiasts find books and courses tailored to their needs.'
        />
        <meta
          name='twitter:description'
          content='ZebraLearn: Where finance enthusiasts find books and courses tailored to their needs.'
        />
        <meta
          name='twitter:image'
          content='https://zebralearn-store.s3.ap-south-1.amazonaws.com/OG+1.png?tr=f-png'
        />
      </Head>

      <div className={montserrat.className}>
        <RecoilRoot>
          <ConfigProvider
            theme={{ ...config, algorithm: [antdTheme.darkAlgorithm] }}
          >
            <AntdApp>
              <LazyMotion features={domAnimation}>
                {excludeLayout ? (
                  <AnimatePresence mode='wait' initial={false}>
                    {content}
                  </AnimatePresence>
                ) : (
                  <>
                    <Layout>
                      {excludeRouteAnimation ? (
                        <>{content}</>
                      ) : (
                        <AnimatePresence mode='wait' initial={false}>
                          {content}
                        </AnimatePresence>
                      )}
                    </Layout>
                  </>
                )}
              </LazyMotion>
            </AntdApp>
          </ConfigProvider>
          <ToastContainer autoClose={2000} theme='dark' />
        </RecoilRoot>
      </div>
    </>
  );
}
