import { XIcon } from '@heroicons/react/outline';
import { Divider, Input, Modal, Progress } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import { AppleLogo, GoogleIcon, ZebraLearnLogo } from '../../../../assets';
import EmailIcon from '../../../../assets/Icons/Mail';
import PasswordIcon from '../../../../assets/Icons/password';
import CustomIcon from '../../../../assets/Icons/User';
import { authModalControl } from '../../../atoms/authModalControl';
import { user } from '../../../atoms/user';
import appwriteService from '../../../config/appwrite';
import { useAuth } from '../../../hooks/useAuth';
import { User } from '../../../utils/interfaces/User';

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isRegister?: boolean;
  noRedirect?: boolean;
  isClosable?: boolean;
  liveSlug?: string;
}

export const calculatePasswordStrength = (password: string) => {
  let strength = 0;

  // Check password length
  if (password.length >= 8) {
    strength += 1;
  }

  // Check for uppercase letters
  if (/[A-Z]/.test(password)) {
    strength += 1;
  }

  // Check for lowercase letters
  if (/[a-z]/.test(password)) {
    strength += 1;
  }

  // Check for at least one digit
  if (/\d/.test(password)) {
    strength += 1;
  }

  // Check for special characters
  if (/[\W_]/.test(password)) {
    strength += 1;
  }

  return strength;
};

export const getStrengthText = (passwordStrength: number) => {
  switch (passwordStrength) {
    case 1:
      return 'Too Weak';
    case 2:
      return 'Weak';
    case 3:
      return 'Moderate';
    case 4:
      return 'Strong';
    case 5:
      return 'Very Strong';
    default:
      return '';
  }
};

const SignUpModal: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  isRegister = false,
  noRedirect = false,
  isClosable = true,
  liveSlug,
}) => {
  const [, setVisible] = useRecoilState(authModalControl);
  const userData: User | undefined = useRecoilValue(user);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const { onSignUp, loading } = useAuth();
  const showAppleLogin =
    process.env.NEXT_PUBLIC_SHOW_APPLE_LOGIN === 'showLogin';

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      handleClose();
    }, 2000);
  };
  const [passwordStrength, setPasswordStrength] = useState<number>(0);

  const [formData, setFormData] = useState({
    fullname: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    retryPassword: '',
  });

  const [errors, setErrors] = useState({
    fullname: '',
    username: '',
    email: '',
    // phone: '',
    password: '',
    retryPassword: '',
  });

  const handleClose = () => {
    setErrors({
      fullname: '',
      username: '',
      email: '',
      // phone: '',
      password: '',
      retryPassword: '',
    });
    setFormData({
      fullname: '',
      username: '',
      email: '',
      phone: '',
      password: '',
      retryPassword: '',
    });
    onCancel();
    // setEmailExist(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'password') {
      const strength = calculatePasswordStrength(value);
      setPasswordStrength(strength);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const validationErrors = {
      fullname: '',
      username: '',
      email: '',
      // phone: '',
      password: '',
      retryPassword: '',
    };
    if (!formData.email) {
      validationErrors.email = 'Email is required';
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = 'Invalid email';
    }
    if (!formData.fullname) {
      validationErrors.fullname = 'Full Name is required';
    }

    if (!formData.password) {
      validationErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      validationErrors.password = 'Password must be at least 8 characters long';
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      const signUpData = {
        email: formData.email,
        password: formData.password,
        name: formData.fullname,
        phone: formData.phone,
        isRegister: isRegister,
        liveSlug: liveSlug,
        noRedirect: noRedirect,
        ...(isRegister && { registerClose: handleClose }),
      };

      onSignUp(signUpData);
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (userData && isRegister) {
      setFormData({
        ...formData,
        email: userData?.email || '',
        fullname: userData?.name || '',
        phone: userData?.phone || '',
      });
    }
  }, [userData]);

  const handleGoogleLogin = async () => {
    setGoogleLoading(true);
    try {
      if (liveSlug) {
        await appwriteService.loginWithGoogle(liveSlug);
      } else {
        await appwriteService.loginWithGoogle();
      }
    } catch (error) {
      console.error('Error logging in with Google:', error);
    } finally {
      setGoogleLoading(false);
      setVisible((prevState) => ({
        ...prevState,
        login: false,
        signUp: false,
      }));
    }
  };

  const handleAppleLogin = async () => {
    setAppleLoading(true);
    try {
      if (liveSlug) {
        await appwriteService.loginWithApple(liveSlug);
      } else {
        await appwriteService.loginWithApple();
      }
    } catch (error) {
      console.error('Error logging in with Google:', error);
    } finally {
      setAppleLoading(false);
      setVisible((prevState) => ({
        ...prevState,
        login: false,
        signUp: false,
      }));
    }
  };

  return (
    <Modal
      title=''
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={handleClose}
      footer={false}
      className='customLoginModal bg-bgColor bg-opacity-70  pt-5  relative rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md '
    >
      <div className='flex items-center gap-y-10 flex-col scroll-px-0.5 px-5 md:px-10  justify-center'>
        <div className={` z-30  flex flex-col gap-y-1 w-full pb-5`}>
          <div className='relative pt-2 md:pt-4'>
            <span className=' flex justify-center w-full'>
              <Image
                src={ZebraLearnLogo}
                alt='Logo'
                priority
                className='object-contain h-3.5 w-full'
              />
            </span>
            <ShouldRender check={isClosable}>
              <span
                onClick={() => {
                  handleClose();
                }}
                className='absolute right-0 top-[40%]  md:top-[40%] cursor-pointer'
              >
                <XIcon className='w-4 h-4 md:w-6 md:h-6 text-fadedWhite' />
              </span>
            </ShouldRender>
          </div>
          <div className='border-b-2 border-backgroundGray py-1.5' />
          <div className={clsx('py-2 flex flex-col ')}>
            <h1 className='text-xl flex justify-center gap-x-2 font-medium lg:font-semibold  mt-1 leading-10 text-fadedWhite text-center w-full'>
              {isRegister ? 'Register' : 'Create account'} for{' '}
              <p className='text-primary font-medium lg:font-semibold'>Free</p>
            </h1>
            <span className='text-sm text-textDarkGrayColor text-center flex justify-center'>
              No cost what-so-ever to{' '}
              {isRegister ? 'register' : 'create account.'}
            </span>
          </div>

          <form onSubmit={handleSubmit} className='flex flex-col mt-2 gap-y-2'>
            <div className='col-span-1 flex-col flex'>
              <Input
                name='fullname'
                value={formData.fullname}
                placeholder='Full Name'
                prefix={
                  <span className='pr-2'>
                    <CustomIcon filled={true} />
                  </span>
                }
                onChange={handleChange}
                style={{
                  backgroundColor: '#000000',
                }}
                size='large'
                className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2  text-white bg-bgColor customInputBlack makeBgBlack'
              />
              {errors.fullname && (
                <p className='text-red-500'>{errors.fullname}</p>
              )}
            </div>
            <div className='col-span-1 flex-col flex gap-y-.5'>
              <Input
                type='email'
                name='email'
                autoComplete='current-email'
                value={formData.email}
                style={{
                  backgroundColor: '#000000',
                }}
                placeholder='Email'
                prefix={
                  <span className='pr-2'>
                    <EmailIcon filled={true} />
                  </span>
                }
                onChange={handleChange}
                size='large'
                className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2  text-white bg-bgColor customInputBlack makeBgBlack'
              />
              {errors.email && <p className='text-red-500'>{errors.email}</p>}
            </div>
            <div className='col-span-1 flex-col flex '>
              <Input.Password
                name='password'
                type='password'
                placeholder='Password'
                autoComplete='current-password'
                value={formData.password}
                style={{
                  backgroundColor: '#000000',
                }}
                prefix={
                  <span className='pr-2'>
                    <PasswordIcon />
                  </span>
                }
                onChange={handleChange}
                size='large'
                className='border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-2  text-white bg-bgColor customInputBlack makeBgBlack'
              />
              {errors.password && (
                <p className='text-red-500'>{errors.password}</p>
              )}
              <ShouldRender check={formData.password}>
                {passwordStrength !== null && (
                  <span className='relative'>
                    <Progress
                      percent={(passwordStrength / 5) * 100}
                      showInfo={false}
                      status={passwordStrength >= 3 ? 'success' : 'exception'}
                    />
                    <p
                      className={`mt-2 text-${
                        passwordStrength >= 3 ? 'green' : 'red'
                      }-500 absolute text-xs -bottom-3 font-medium`}
                    >
                      {getStrengthText(passwordStrength)}
                    </p>
                  </span>
                )}
              </ShouldRender>
            </div>

            <span className='flex justify-center mt-2'>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.WHITE}
                className='p-1 w-full'
                disabled={loading}
                loading={loading}
                rounded={true}
                enableScaling={false}
              >
                <span className='flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap'>
                  {isRegister ? 'Register' : 'Sign up'}
                </span>
              </ButtonDefault>
            </span>

            <span className='text-center font-medium flex text-white justify-center text-sm pt-2'>
              Already have an account?
              <p
                onClick={() => {
                  setVisible((prevState) => ({
                    ...prevState,
                    login: true,
                    signUp: false,
                  }));
                  if (isRegister) {
                    handleClose();
                  }
                }}
                className='text-primary  px-1 hover:font-bold hover:underline cursor-pointer transition-all duration-300 ease-in-out'
              >
                Log in
              </p>
            </span>

            <span className='flex justify-center'>
              <span className='text-center text-base text-fadedWhite w-1/3'>
                <Divider
                  style={{
                    margin: '8px 0',
                    color: '#1C1C1C',
                  }}
                  className='text-fadedWhite text-sm'
                >
                  OR
                </Divider>
              </span>
            </span>
            <span className='flex justify-center '>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.UNSTYLED}
                className='disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base'
                rounded={true}
                loading={googleLoading}
                spinColor='gray'
                disabled={googleLoading}
                onClick={(e) => {
                  e.preventDefault();
                  handleGoogleLogin();
                }}
              >
                <span className='flex text-white   items-center font-medium gap-x-3 px-8  whitespace-nowrap'>
                  <span className='w-5 h-5'>
                    <Image
                      src={GoogleIcon}
                      alt='GoogleLogo'
                      className='object-contain'
                    />
                  </span>{' '}
                  Continue with Google
                </span>
              </ButtonDefault>
            </span>
            <ShouldRender check={showAppleLogin}>
              <span className='flex justify-center '>
                <ButtonDefault
                  size={4}
                  variant={ButtonVariants.UNSTYLED}
                  loading={appleLoading}
                  spinColor='gray'
                  rounded={true}
                  disabled={appleLoading}
                  className='disabled:bg-bgDarkGray hover:bg-[#181818] disabled:text-gray-300 text-fadedWhite bg-black w-full  border border-bgDarkGray shadow-sm text-base'
                  onClick={(e) => {
                    e.preventDefault();
                    handleAppleLogin();
                  }}
                >
                  <span className='flex text-white   items-center font-medium gap-x-3 px-8  whitespace-nowrap'>
                    <span className='w-4'>
                      <Image
                        src={AppleLogo}
                        alt='GoogleLogo'
                        className='object-contain'
                      />
                    </span>{' '}
                    Continue with Apple
                  </span>
                </ButtonDefault>
              </span>
            </ShouldRender>

            <div className='flex justify-center'>
              <p className='text-center text-fadedWhite text-sm  md:w-2/3 '>
                By using our service, you are agreeing to our{' '}
                <a
                  href='/Privacy_Policy_Reader.html'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-primary font-medium cursor-pointer hover:text-darkPrimary'
                >
                  Terms
                </a>{' '}
                and{' '}
                <a
                  href='/Privacy_Policy_Reader.html'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-primary font-medium cursor-pointer hover:text-darkPrimary'
                >
                  conditions
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpModal;
