import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from 'firebase/messaging';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UAParser } from 'ua-parser-js';

import { chatCount } from '../../atoms/chatCount';
import { user } from '../../atoms/user';
import { notify } from '../../components/shared/notify';
import appwriteService from '../../config/appwrite';
import { setFirebaseToken } from '../../services/Firebase';

const generateUniqueId = (zl_uid: string): string => {
  const parser = new UAParser();
  const result = parser.getResult();
  let deviceType = 'web';

  if (result.device.type) {
    deviceType = result.device.type.toLowerCase();
  } else if (/android/i.test(result.os.name || '')) {
    deviceType = 'android';
  }

  return `ecom.${deviceType}.${zl_uid}`;
};

const useFirebaseNotifications = () => {
  const [fcmToken, setFcmToken] = useState<string | undefined>();
  const [isMessagingInitialized, setIsMessagingInitialized] = useState(false);
  const [messaging, setMessaging] = useState<Messaging | null>(null);
  const userData = useRecoilValue(user);
  const [, setChatCount] = useRecoilState<number>(chatCount);
  useEffect(() => {
    const initialize = async () => {
      try {
        const messagingInstance = getMessaging();
        setMessaging(messagingInstance);

        if (
          messagingInstance &&
          'serviceWorker' in navigator &&
          ['staging', 'production'].includes(
            process.env.NEXT_PUBLIC_APP_ENV as string,
          )
        ) {
          navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then(() => {
              setIsMessagingInitialized(true);
            });
        }
      } catch (error) {
        if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
          console.error('Error initializing messaging:', error);
        }
      }
    };

    initialize();
  }, []);

  const setupNotifications = async (): Promise<void> => {
    if (!messaging) {
      console.error('Firebase Messaging is not initialized.');
      return;
    }

    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const deviceId = Cookies.get('device_id');
        const device_token_id = Cookies.get('device_token_id');
        const token = await getToken(messaging);

        if (token && userData && !device_token_id) {
          const newDevice_id = generateUniqueId(userData.zl_uid as string);

          const tokenId = await setFirebaseToken({
            user_id: userData?.id?.toString(),
            token,
            device: 'web',
            device_id: deviceId ?? newDevice_id,
            zl_uid: userData?.zl_uid,
          });

          if (tokenId?.id) {
            Cookies.set('device_token_id', tokenId?.id);
          }
          await appwriteService.createTarget({
            target_id: tokenId.device_id,
            identifier: tokenId.token,
            provider_id: process.env.NEXT_PUBLIC_PROVIDER_ID as string,
          });

          setFcmToken(token);
          if (!deviceId) {
            Cookies.set('device_id', newDevice_id);
          }
        }
      } else {
        console.log(
          'Notifications are blocked. Please enable them from your browser settings.',
        );
        setFcmToken('');
      }
    } catch (error) {
      console.error('Error setting up notifications:', error);
    }
  };

  const deleteFCMToken = async (): Promise<void> => {
    try {
      await indexedDB.deleteDatabase('firebase-messaging-database');
      await indexedDB.deleteDatabase('firebase-installations-database');
      await indexedDB.deleteDatabase('firebase-heartbeat-database');
      await indexedDB.deleteDatabase('firebaseLocalStorageDb');
      setFcmToken(undefined);
    } catch (error) {
      console.error('Error clearing site data:', error);
    }
  };

  const notification = () => {
    if (!messaging) {
      console.error('Firebase Messaging is not supported.');
      return;
    }

    onMessage(messaging, (payload) => {
      if (payload?.notification?.body) {
        notify(payload?.notification?.body.replace(/:/g, ''), 'info');
      }

      if (payload?.data?.title) {
        notify(payload?.data?.title, 'info', payload?.data?.url);
        setChatCount((prevCount) => prevCount + 1);
      }
    });
  };

  return {
    fcmToken,
    isMessagingInitialized,
    setupNotifications,
    deleteFCMToken,
    notification,
  };
};

export default useFirebaseNotifications;
