/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from 'js-cookie';
import { Montserrat } from 'next/font/google';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { ReactNode } from 'react';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import ProductsHeader from './ProductsHeader';
import { extractSlugAndProductId } from '../BookLandingPageComp/SlidBookContainer';
import Footer from '../Footer';
import { removeUserId } from '../shared/getToken';
import { notify } from '../shared/notify';
import { tokenUpdate } from '../../atoms/authModalControl';
import { chatCount } from '../../atoms/chatCount';
import { localCart } from '../../atoms/LocalCart';
import { localUserID, user } from '../../atoms/user';
import appwriteService, { setTokenInCookies } from '../../config/appwrite';
import { appWriteUser, useAuth } from '../../hooks/useAuth';
import usePageTracking from '../../hooks/usePageTracking';
import { affiliateLinkClicks } from '../../services/Affiliate';
import { getCartCount } from '../../services/Cart';
import { getChatCount } from '../../services/Chat';
import { liveRegistrationAlreadyRegistered } from '../../services/Live';
import { getUserProfile } from '../../services/user';
import { footerData } from '../../utils/data';
import useFirebaseNotifications from '../../utils/firebase/firebaseSetup';
import { register } from '../../utils/firebase/serviceWorkerRegistration';
import { AppwriteUserData, User } from '../../utils/interfaces/User';

interface Props {
  children: ReactNode;
}

const montserrat = Montserrat({
  subsets: ['latin'],
  fallback: ['Arial', 'sans-serif'],
});

const Layout = ({ children }: Props) => {
  const router = useRouter();
  const hasEffectRun = useRef(false);
  const [, setLocalCartCount] = useRecoilState(localCart);
  const [, setLocalUserId] = useRecoilState<string>(localUserID);
  const [isTokenChanged, setIsTokenChanged] = useRecoilState(tokenUpdate);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [userData, setUserData] = useRecoilState<User | undefined>(user);
  const [, setChatCount] = useRecoilState<number>(chatCount);
  const { setupNotifications, notification } = useFirebaseNotifications();
  const [notificationsSetup, setNotificationsSetup] = useState(false);
  const { asPath } = router;

  const {
    afc,
    googleLoginCompleted,
    isRegister,
    bookId,
    appleLoginCompleted,
    ref_code,
  } = router.query;
  const productId = extractSlugAndProductId(router.asPath);

  const { handlePostSignUpData } = useAuth();

  const isStagingEnv = ['staging', 'production'].includes(
    process.env.NEXT_PUBLIC_APP_ENV as string,
  );

  const solePagePaths = [
    '/auth/forgot-password',
    '/auth/login/',
    '/auth/sign_up',
    '/shop',
    '/shop/bookDetails',
    '/shop/bundleDetails',
    '/privacy_policy/',
    '/error/',
    '/500',
    '/auth/reset-password',
    '/get-our-app',
    '/reader-personality-test',
    '/onboarding',
  ];

  const authPagePaths = [
    '/my-orders/',
    '/manage-addresses/',
    '/profile/',
    '/library/',
    '/broadcast-message/',
    // '/live/watch/'
    ...(isStagingEnv ? ['/notification/'] : []),
    // '/cart/',
    '/pdf/',
    '/refer-earn/',
    '/rewards/',
    '/rewards/history/',
    '/onboarding/',
    '/reward-store/',
  ];
  const links = [
    { text: 'Home', route: '/' },
    { text: 'Books', route: '/books' },
    { text: 'Courses', route: '/courses' },
    { text: 'Schools', route: '/schools' },
    { text: 'Live', route: '/live' },
    {
      text: 'For Business',
      route: 'https://business.zebralearn.com/business',
    },
    { text: 'Contact Us', route: '/contact' },
  ];

  const isSolePage =
    solePagePaths.includes(router.pathname) ||
    solePagePaths.includes(`${router.pathname}/`);

  const isAuthenticated =
    authPagePaths.includes(router.pathname) ||
    authPagePaths.includes(`${router.pathname}/`) ||
    authPagePaths.some((path) => router.pathname.startsWith(path));

  const checkAuthentication = async () => {
    try {
      await getUserProfile();
    } catch {
      notify('You are not authenticated', 'error');
      if (router.pathname.includes('/pdf')) {
        router.push(`/auth/login`);
      } else {
        router.push(`/auth/login?redirectUrl=${router.pathname}`);
      }
    }
  };

  const handleChatCount = async () => {
    try {
      const data = await getChatCount();
      setChatCount(data?.messages);
      // setLocalCartCount(data.count);
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const setUpdatedProfile = async () => {
    try {
      const data = await getUserProfile();
      setUserData(data?.data);
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  useEffect(() => {
    const fetchLoggedInStatus = async () => {
      const loggedInStatus = await appwriteService.isLoggedIn();
      setLoggedIn(loggedInStatus);
    };
    fetchLoggedInStatus();
    if (isTokenChanged) {
      setIsTokenChanged(false);
    }
  }, [appwriteService.account, isTokenChanged, router.pathname]);

  useEffect(() => {
    if (loggedIn) {
      setUpdatedProfile();
    }
  }, [loggedIn]);

  useEffect(() => {
    handleCartCount();
  }, [router.pathname]);

  useEffect(() => {
    if (loggedIn) {
      handleChatCount();
    }
  }, [loggedIn, router.pathname]);

  useEffect(() => {
    if (router.isReady && afc && !loggedIn) {
      Cookies.set('afc', afc as string);
      handleLinkClicks(afc as string);
    }
    if (router.isReady && ref_code) {
      Cookies.set('ref_code', ref_code as string, { expires: 30 });
    }
  }, [afc, ref_code, router.isReady]);

  useEffect(() => {
    if (router.isReady) {
      const query = router.query;

      if (typeof query === 'object' && query !== null) {
        const filteredQuery: { [key: string]: any } = {};

        for (const key in query) {
          if (Object.prototype.hasOwnProperty.call(query, key)) {
            if (
              typeof key === 'string' &&
              (key.startsWith('mc_') || key.startsWith('utm_'))
            ) {
              filteredQuery[key] = query[key];
            }
          }
        }
        if (Object.keys(filteredQuery).length > 0) {
          const filteredQueryStr = JSON.stringify(filteredQuery);
          const expireTime =
            Number(process.env.NEXT_PUBLIC_CASH_UTM_EXPIRY) || 7;
          Cookies.set('marketing_data', filteredQueryStr, {
            expires: expireTime,
          });
        }
      }
    }
  }, [router]);

  const handleLinkClicks = async (afc: string) => {
    try {
      await affiliateLinkClicks(afc);
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const handleCartCount = async (localId?: string) => {
    try {
      const data = await getCartCount({ localId });
      setLocalCartCount(data.count);
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  useEffect(() => {
    const setUserIdInLocalStorage = async () => {
      const existingUserId = localStorage.getItem('userId');

      if (!existingUserId) {
        const userId = uuidv4();

        localStorage.setItem('userId', userId);
        setTimeout(() => {
          // setCartData([]);
          setLocalCartCount(0);
          removeUserId();
        }, 2000);

        setLocalUserId(userId);
      }
      setLocalUserId(existingUserId as string);
    };

    setUserIdInLocalStorage();
  }, [router.asPath]);

  useEffect(() => {
    if (isAuthenticated && !hasEffectRun.current) {
      checkAuthentication();
      hasEffectRun.current = true;
    }
  }, [isAuthenticated, router, checkAuthentication]);

  useEffect(() => {
    const fetchTokenAndTriggerChange = async () => {
      if (googleLoginCompleted === 'true' || appleLoginCompleted === 'true') {
        const token = await setTokenInCookies();
        if (
          router.pathname.includes('/auth/login') ||
          router.pathname.includes('/auth/sign_up')
        ) {
          router.push('/');
        }
        const user = await appwriteService.getCurrentUser();
        const currentUser = user as unknown as AppwriteUserData;
        const dbUser: User = await appWriteUser(currentUser?.$id as string);
        await handlePostSignUpData({
          dbUser,
          token: token as string,
          loginType: appleLoginCompleted ? 'apple' : 'google',
        });
        setIsTokenChanged(true);
        if (
          !(
            router.pathname.includes('/auth/login') ||
            router.pathname.includes('/auth/sign_up')
          )
        ) {
          const url = new URL(asPath, window.location.origin);
          if (isRegister) {
            await liveRegistrationAlreadyRegistered({
              email: dbUser?.email as string,
              live_workshop_slug: isRegister as string,
              name: dbUser?.name as string,
              ecom_user_id: dbUser?.id as number,
            });
            url.searchParams.delete('isRegister');
          }

          url.searchParams.delete('googleLoginCompleted');
          url.searchParams.delete('appleLoginCompleted');

          router.replace(url.pathname + url.search);
        }
      }
    };
    fetchTokenAndTriggerChange();
  }, [googleLoginCompleted, appleLoginCompleted, isRegister]);

  // FireBase
  useEffect(() => {
    if (
      'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window &&
      userData &&
      !notificationsSetup
    ) {
      setupNotifications();
      register();
      notification();
      setNotificationsSetup(true);
    } else if (
      !('Notification' in window) ||
      !('serviceWorker' in navigator) ||
      !('PushManager' in window)
    ) {
      console.error('Notification not supported by your browser');
    }
  }, [userData, notificationsSetup]);
  usePageTracking();

  return (
    <>
      <div className={montserrat.className}>
        {!isSolePage && (
          <ProductsHeader
            isShadow={router.asPath === '/about_us/'}
            links={links}
          />
        )}
        {children}
        {!isSolePage &&
          router.isReady &&
          router.pathname !== '/cart' &&
          router.pathname !== '/checkout' &&
          router.pathname !== '/broadcast-message' &&
          router.pathname !== '/personality_test' &&
          router.pathname !== '/onboarding' &&
          !router.pathname.includes('/pdf') &&
          !(bookId && productId) && (
            <Footer navigation={footerData.navigation} />
          )}
      </div>
    </>
  );
};
export default Layout;
