import React from 'react';

const SearchIcon: React.FC = () => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Search'>
        <circle
          id='Ellipse_739'
          cx='8.80589'
          cy='8.80553'
          r='7.49047'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
        <path
          id='Line_181'
          d='M14.0156 14.4043L16.9523 17.3334'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
