import Cookies from 'js-cookie';
import { Dictionary, isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { EventDetails, logEvent } from './useClickEventTracking';
import { authModalControl, tokenUpdate } from '../atoms/authModalControl';
import { cartAtom, checkCouponAtom } from '../atoms/Cart';
import { localCart } from '../atoms/LocalCart';
import { localUserID, user } from '../atoms/user';
import { extractSlugAndProductId } from '../components/BookLandingPageComp/SlidBookContainer';
import { formatCartData } from '../components/CartPage';
import {
  clearSession,
  setSessionValid,
} from '../components/shared/AppWriteLocalStroage';
import { notify } from '../components/shared/basic/notify';
import {
  formateErrorAppwriteObject,
  formateErrorObject,
} from '../components/shared/formateErrorObject';
import {
  logout,
  setToken,
  setUserId,
  setUserName,
  setZl_UID,
} from '../components/shared/getToken';
import {
  trackCampaignSignUp,
  trackLoginEvent,
  trackSignupEvent,
} from '../components/shared/Gtags';
import { resetSessionExistsCookie } from '../components/shared/ManageTokenForClient/manageCookies';
import appwriteService, { setTokenInCookies } from '../config/appwrite';
import { Endpoints } from '../network';
import { affiliateCodeSend } from '../services/Affiliate';
import { apiClient } from '../services/apiClients';
import { getCartCount, handleCheckCoupon } from '../services/Cart';
import {
  deleteFirebaseToken,
  deleteFirebaseTokenNotification,
} from '../services/Firebase';
import { liveRegistrationAlreadyRegistered } from '../services/Live';
import { subscribeForUpcomingBooks } from '../services/Products';
import useFirebaseNotifications from '../utils/firebase/firebaseSetup';
import { AppwriteUserData, User } from '../utils/interfaces/User';

interface LoginOptions {
  email: string;
  password: string;
  login_by: string;
  redirectUrl?: string;
  isModal?: boolean;
  onCancel?: () => void;
  noRedirection?: boolean;
  setEmailExistAndPassword?: any;
  bookId?: string;
  isIFrame?: boolean;
}

export interface SignUpOptions {
  email: string;
  password: string;
  name: string;
  phone: string;
  isModal?: boolean;
  onCancel?: () => void;
  noRedirect?: boolean;
  isRegister?: boolean;
  liveSlug?: string;
  registerClose?: () => void;
}

export const useAuth = () => {
  // const token = getLoginToken();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useRecoilState(authModalControl);
  const [, setIsTokenChanged] = useRecoilState(tokenUpdate);

  const [userData, setUserData] = useRecoilState<User | undefined>(user);
  const localUserId = useRecoilValue(localUserID);
  const [, setCartData] = useRecoilState(cartAtom);
  const [, setLocalCartCount] = useRecoilState(localCart);
  const [, setCouponData] = useRecoilState(checkCouponAtom);
  const { deleteFCMToken } = useFirebaseNotifications();
  const router = useRouter();
  const {
    bookId: book_id,
    postId,
    schoolId,
    watchId,
    slug,
    school_id,
    workshop_id,
    post_id,
    cartId,
  } = router.query;
  const productBookId = extractSlugAndProductId(router.asPath);
  const handleCartDetails = async (userId: string) => {
    try {
      const data = await apiClient.post(`${Endpoints.getCartDetails}`, {
        user_id: userId,
      });

      const formattedData = formatCartData(
        data?.data?.data?.[0]?.cart_items,
        data?.data?.cart_id,
      );
      setCartData(formattedData);
      await handleCartCount(userId);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  const handleCartCount = async (userId: string) => {
    try {
      const data = await getCartCount({ id: userId });
      // setUserData(data?.data);
      setLocalCartCount(data.count);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  const onLogin = async (
    options: LoginOptions,
  ): Promise<Dictionary<string | JSX.Element>> => {
    const {
      email,
      password,
      // login_by,
      redirectUrl,
      isModal,
      onCancel,
      noRedirection = false,
      setEmailExistAndPassword,
      bookId,
      isIFrame,
    } = options;

    setLoading(true);
    const errorMessages: Dictionary<JSX.Element | string> = {};

    // Destructure options or use default values

    let notificationSent = false;

    try {
      await appwriteService.logoutIfSessionExists();
      const response = await appwriteService.login({
        email,
        password,
      });
      setSessionValid(true);
      if (!isIFrame) {
        const token = await setTokenInCookies();
        let currentUser: AppwriteUserData | undefined = undefined;

        if (response) {
          const user = await appwriteService.getCurrentUser();
          if (user !== null) {
            currentUser = user as unknown as AppwriteUserData;
          }
        }
        const dbUser: User = await appWriteUser(currentUser?.$id as string);

        await setToken(token as string);
        await setUserName(dbUser?.name);
        setUserData(dbUser);
        setIsTokenChanged(true);
        await setUserId(dbUser?.id?.toString());
        await setZl_UID(dbUser?.zl_uid?.toString() as string);
        resetSessionExistsCookie();
        clearSession();
        if (visible.comingSoon) {
          await handleSubscribe(visible.comingSoon);
        }

        if (dbUser?.id?.toString()) {
          trackLoginEvent({
            user_id: dbUser?.id?.toString(),
            email: dbUser?.email,
            name: dbUser?.name,
            phone: dbUser?.phone,
          });
        }

        const eventParams = constructEventParams({
          router,
          dbUser,
          book_id: book_id as string,
          schoolId: schoolId as string,
          postId: postId as string,
          slug: slug as string,
          watchId: watchId as string,
          eventName: 'login',
        });

        logEvent({
          eventDetails: eventParams,
          pathname: router.pathname,
        });

        if (localUserId) {
          await syncCart(dbUser?.id?.toString(), Number(cartId));
          const checkData = await handleCheckCoupon(Number(cartId));
          if (typeof checkData?.data !== undefined) {
            setCouponData(checkData.data);
          }
        }
        if (setEmailExistAndPassword) {
          setEmailExistAndPassword((prevErrors: any) => ({
            ...prevErrors,
            emailExist: false,
          }));
        }
        if (
          dbUser?.user_attributes?.find(
            (attr) => attr.attribute_key === 'onboarding_completed',
          )?.attribute_value !== '1' &&
          !router.pathname.includes('/cart') &&
          !router.pathname.includes('/personality_test')
        ) {
          router.push('/onboarding');
        } else {
          if (!noRedirection) {
            if (!isModal) {
              await router.push(redirectUrl ?? '/');
            } else {
              onCancel && onCancel();
            }
          }
        }

        if (isEmpty(dbUser)) {
          throw Error();
        }

        if (!notificationSent) {
          if (bookId) {
            router.push(`/pdf/${bookId}?samplePdf=true`);
          }
          setVisible((prevState) => ({
            ...prevState,
            login: false,
            signUp: false,
            bookId: '',
            comingSoon: '',
          }));
          // notify('Successfully Logged IN!', 'success');
          notificationSent = true;
        }
      } else {
        // await setAuthToken(data?.data?.access_token);
        router.reload();
        setIsTokenChanged(true);
      }
    } catch (error: any) {
      const message = formateErrorAppwriteObject(error);

      notify(message, 'error');

      if (setEmailExistAndPassword) {
        setEmailExistAndPassword((prevErrors: any) => ({
          ...prevErrors,
          emailExist: true,
        }));
      }
      throw error;
    } finally {
      setLoading(false);
    }

    return errorMessages;
  };

  const syncCart = async (user_id: string, cartId?: number) => {
    try {
      if (user_id) {
        await apiClient.post(Endpoints.syncCart, {
          user_id: user_id,
          temp_user_id: localUserId,
          ...(cartId && { cart_id: cartId }),
        });
      } else {
        await apiClient.post(Endpoints.syncCart, {
          temp_user_id: localUserId,
          ...(cartId && { cart_id: cartId }),
        });
      }

      if (user_id) {
        handleCartDetails(user_id);
      }
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
      throw error;
    }
  };

  const handleDeviceToken = async (
    device_token_id: string | undefined,
  ): Promise<boolean> => {
    try {
      if (device_token_id) {
        await deleteFirebaseTokenNotification();
        await deleteFirebaseToken(device_token_id);
      }

      const eventParams: EventDetails = {
        event_name: 'logout',
        source: 'global',
        source_type: 'global',
        source_id: null,
      };

      await logEvent({
        eventDetails: eventParams,
        userData,
        pathname: router.pathname,
      });

      return true;
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
      return true;
    }
  };

  const performLogout = async () => {
    setUserData(undefined);
    setCartData([]);

    // Always remove cookies and clear session
    Cookies.remove('user_id');
    Cookies.remove('auth_token');
    Cookies.remove('userName');
    Cookies.remove('book_token');
    Cookies.remove('device_token_id');
    Cookies.remove('device_id');
    clearSession();
    resetSessionExistsCookie();

    try {
      await appwriteService.logout();
      deleteFCMToken();
      logout();
      router.reload();
      notify('Logged out successfully', 'success');
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onLogOut = async (): Promise<Dictionary<string | JSX.Element>> => {
    const errorMessages: Dictionary<JSX.Element | string> = {};
    const device_token_id = Cookies.get('device_token_id');

    // Handle device token operations and check if it succeeded
    handleDeviceToken(device_token_id);

    // Perform the rest of the logout process
    await performLogout();

    return errorMessages;
  };

  const handleSubscribe = async (productId: string) => {
    try {
      if (productId) {
        await subscribeForUpcomingBooks(productId);
      }
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  const constructEventParams = ({
    router,
    dbUser,
    book_id,
    schoolId,
    postId,
    slug,
    watchId,
    eventName,
    sso,
  }: {
    router: any;
    dbUser: any;
    book_id?: string;
    schoolId?: string;
    postId?: string;
    slug?: string;
    watchId?: string;
    eventName: string;
    sso?: string;
  }): EventDetails => {
    const getSourceByPath = (path: string) =>
      path.includes('books')
        ? 'books'
        : path.includes('course')
          ? 'course'
          : path.includes('schools')
            ? 'schools'
            : path.includes('live')
              ? 'live'
              : 'global';

    const source = getSourceByPath(router.pathname);
    const eventParams: EventDetails = {
      event_name: eventName,
      source,
      source_type: source,
      source_id: null,
      sub_source: null,
      sub_source_id: null,
      zl_uid: dbUser?.zl_uid,
    };

    if (sso) {
      Object.assign(eventParams, {
        meta: {
          sso: sso,
        },
      });
    }
    if (book_id) {
      Object.assign(eventParams, {
        source: 'books',
        source_type: 'books',
        source_id: productBookId as string,
      });
    } else if (schoolId) {
      Object.assign(eventParams, {
        source: 'schools',
        source_type: 'schools',
        source_id: school_id,
      });
      if (postId) {
        Object.assign(eventParams, {
          sub_source: 'chapter',
          sub_source_id: postId,
          source_type: post_id,
        });
      }
    } else if (slug || watchId) {
      Object.assign(eventParams, {
        source: 'live',
        source_id: workshop_id,
      });
    }

    return eventParams;
  };

  const onSignUp = async (
    options: SignUpOptions,
  ): Promise<Dictionary<string | JSX.Element>> => {
    const {
      email,
      password,
      name,
      isModal,
      onCancel,
      noRedirect,
      isRegister,
      registerClose,
      liveSlug,
    } = options;
    setLoading(true);
    const errorMessages: Dictionary<JSX.Element | string> = {};

    let notificationSent = false;

    try {
      await appwriteService.logoutIfSessionExists();
      await appwriteService.createUserAccount({
        email,
        password,
        name,
      });
      await appwriteService.logoutIfSessionExists();
      const userLogin = await appwriteService.login({
        email,
        password,
      });
      const token = await setTokenInCookies();
      let currentUser: AppwriteUserData | undefined = undefined;

      if (userLogin) {
        const user = await appwriteService.getCurrentUser();
        if (user !== null) {
          currentUser = user as unknown as AppwriteUserData;
        }
      }
      const dbUser: User = await appWriteUser(currentUser?.$id as string);
      if (isRegister && liveSlug) {
        await liveRegistrationAlreadyRegistered({
          email: dbUser?.email as string,
          live_workshop_slug: liveSlug as string,
          name: dbUser?.name as string,
          ecom_user_id: dbUser?.id as number,
        });
      }

      const eventParams = constructEventParams({
        router,
        dbUser,
        book_id: book_id as string,
        schoolId: schoolId as string,
        postId: postId as string,
        slug: slug as string,
        watchId: watchId as string,
        eventName: 'signup',
      });

      logEvent({
        eventDetails: eventParams,
        pathname: router.pathname,
      });
      if (
        dbUser?.user_attributes?.find(
          (attr) => attr.attribute_key === 'onboarding_completed',
        )?.attribute_value !== '1' &&
        !router.pathname.includes('/cart') &&
        !router.pathname.includes('/personality_test')
      ) {
        router.push('/onboarding');
      } else {
        if (!noRedirect) {
          if (!isModal) {
            if (visible.bookId) {
              await router.push(`/pdf/${visible.bookId}?samplePdf=true`);
            } else {
              await router.push('/');
            }
          } else {
            onCancel && onCancel();
          }
        }
      }

      if (visible.comingSoon) {
        handleSubscribe(visible.comingSoon);
      }

      if (book_id || postId) {
        await trackCampaignSignUp({
          email: email,
          name: name,
          ...(book_id && { origin: 'book', slug: book_id as string }),
          ...(postId && { origin: 'school', slug: postId as string }),
        });
      }

      if (isRegister && registerClose) registerClose();

      if (!notificationSent) {
        setVisible((prevState) => ({
          ...prevState,
          signUp: false,
          login: false,
          comingSoon: '',
        }));
        notify('Successfully Signed Up!', 'success');
        notificationSent = true;
      }
      resetSessionExistsCookie();
      clearSession();
      await handlePostSignUpData({ dbUser, token });
      if (dbUser?.id?.toString()) {
        trackSignupEvent({
          email,
          name,
          password,
          user_id: dbUser?.id?.toString(),
        });
      }
    } catch (error: any) {
      const message = formateErrorAppwriteObject(error);

      notify(message, 'error');
      if (
        error.code === 400 &&
        error.type === 'general_bad_request' &&
        (router.asPath.includes('/cart') || router.asPath.includes('/checkout'))
      ) {
        setVisible((prevState) => ({
          ...prevState,
          signUp: false,
          login: true,
          comingSoon: '',
        }));
      }

      notificationSent = true;
      throw error;
    } finally {
      setLoading(false);
    }
    return errorMessages;
  };

  const shortSignUp = async (
    email: string,
    name: string,
    phone: string,
  ): Promise<Dictionary<string | JSX.Element>> => {
    setLoading(true);
    const errorMessages: Dictionary<JSX.Element | string> = {};

    let notificationSent = false;

    try {
      const response = await apiClient.post(Endpoints.shortSignUp, {
        email,
        name,
        phone,
        temp_user_id: localUserId,
      });
      const { data } = response;

      if (localUserId) {
        syncCart(data?.data?.user?.id, Number(cartId));
      }

      await setToken(data?.data?.access_token?.plainTextToken);
      await setUserName(data?.data?.user?.name);
      setUserData(data?.data?.user);

      await setUserId(data?.data?.user?.id);
      await setZl_UID(data?.data?.user?.zl_uid);
      if (!notificationSent) {
        notificationSent = true;
      }
    } catch (error: any) {
      notify(formateErrorObject(error), 'error');

      if (!notificationSent) {
        notificationSent = true;
      }
    } finally {
      setLoading(false);
    }
    return errorMessages;
  };

  const handlePostSignUpData = async (response: {
    dbUser: User;
    token: string;
    loginType?: string;
  }) => {
    const isRegisterLogin = response?.token;

    await setUserId(response?.dbUser?.id?.toString());
    await setZl_UID(response?.dbUser?.zl_uid?.toString() as string);
    if (localUserId) {
      if (isRegisterLogin) {
        syncCart(response?.dbUser?.id?.toString(), Number(cartId));
      } else {
        syncCart(response?.dbUser?.id?.toString(), Number(cartId));
      }
    }
    if (response?.loginType) {
      if (response?.dbUser?.new_registration) {
        const eventParams = constructEventParams({
          router,
          dbUser: response?.dbUser,
          book_id: book_id as string,
          schoolId: schoolId as string,
          postId: postId as string,
          slug: slug as string,
          watchId: watchId as string,
          eventName: 'signup',
          sso: response?.loginType,
        });

        logEvent({
          eventDetails: eventParams,
          pathname: router.pathname,
        });
      } else {
        const eventParams = constructEventParams({
          router,
          dbUser: response?.dbUser,
          book_id: book_id as string,
          schoolId: schoolId as string,
          postId: postId as string,
          slug: slug as string,
          watchId: watchId as string,
          eventName: 'login',
          sso: response?.loginType,
        });

        logEvent({
          eventDetails: eventParams,
          pathname: router.pathname,
        });
      }
    }
    if (Cookies.get('marketing_data')) {
      const data = JSON.parse(Cookies.get('marketing_data') as string);
      await appwriteService.updatePrefs({ data });
    }

    await setToken(response?.token);
    await setUserName(response?.dbUser?.name);

    setUserData(response?.dbUser);
    await setUserId(response?.dbUser?.id?.toString());
    await setZl_UID(response?.dbUser?.zl_uid?.toString() as string);
    const affiliateCode = Cookies.get('afc');
    if (affiliateCode) {
      affiliateCodeSend(affiliateCode);
      Cookies.remove('afc');
    }

    setIsTokenChanged(true);
  };

  return {
    loading,
    onLogin,
    onSignUp,
    onLogOut,
    shortSignUp,
    syncCart,
    handlePostSignUpData,
  };
};

export const appWriteUser = async (zl_uid: string) => {
  try {
    const ref_code = Cookies.get('ref_code');

    const requestBody = {
      zl_uid,
      ...(ref_code && { ref_code }),
    };

    const response = await apiClient.post(Endpoints.appWriteUser, requestBody);

    return response?.data?.data;
  } catch (error: any) {
    if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
      console.error('Error initializing messaging:', error);
    }
  }
};
